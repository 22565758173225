import { defineStore } from 'pinia'

export const useDialogStore = defineStore({
  id: 'dialog',
  state: () => ({
    content:{
      title: '',
      supportingText: '',
      primaryButton: '',
      secondaryButton: '',
    },
    actions: '',
  }),

  getters: {
    hasDialogMsg: (state) => ![state.content.title, state.content.supportingText].includes(''),
  },

  actions: {
    set({ title, supportingText, primaryButton, secondaryButton }) {
      this.content = { title, supportingText, primaryButton, secondaryButton }
    },
    clear() {
      this.content = {
        title: '',
        supportingText: '',
        primaryButton: '',
        secondaryButton: '',
      }
    },
    action(text) {
      this.actions = text
    },
  },
})