<template>
  <div
    :class="[onActive ? 'onActive' : '']"
    class="sideBarOptionsBox"
    :tabindex="props.tabindex"
    @click="triggerInput"
  >
    <div v-show="isEmpty && !onActive" class="placeholder">
      <div class="left">
        <div class="placeholding title">
          {{ props.itemName }}
        </div>
      </div>
    </div>
    <div :class="[!isEmpty || onActive ? 'show' : 'hide', 'editor']">
      <div class="left">
        <div class="textInput">
          <div class="title">{{ props.itemName }}</div>
          <input
            v-if="!isRichText"
            v-model="inputTxt"
            ref="textInput"
            class="input"
            :type="props.type"
            :placeholder="props.placeholder"
            :autocomplete="props.autocomplete"
            @input="$emit('update:modelValue', inputTxt)"
            @focus="onActive = true"
            @blur="onActive = false"
            @keyup.enter="$emit('onPressedEnter')"
          />
          <div v-else class="input">{{ richText }}</div>
        </div>
      </div>
    </div>
  </div>
  <RichEditor
    v-if="showRichEditor"
    v-model:isEdit="showRichEditor"
    v-model:content="inputTxt"
    type="normal"
  />
  <SideBarItemDetail v-if="description" :description="props.description" />
</template>

<script setup>
import { ref, defineProps, watch, computed, defineEmits, onMounted } from "vue"
import SideBarItemDetail from "./SideBarItemDetail.vue"
import RichEditor from "./RichEditor.vue"

const props = defineProps({
  modelValue: String,
  itemName: String,
  description: String,
  text: String,
  isRichText: {
    type: Boolean,
    default: false,
  },
  tabindex: Number,
  autocomplete: String,
  type: {
    type: String,
    default: "text",
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "寫點什麼⋯⋯",
  },
})

let onActive = ref(false)
const showRichEditor = ref(false)

const textInput = ref(null)

onMounted(() => {
  if (props.autoFocus == true) {
    onActive.value = true
    setTimeout(() => textInput.value.focus())
  }
})

const triggerInput = () => {
  if (!props.isRichText) {
    onActive.value = true
    setTimeout(() => textInput.value.focus())
  } else {
    showRichEditor.value = true
  }
}

const inputTxt = ref("")
watch(
  () => props.text,
  () => (inputTxt.value = props.text || ""),
  { immediate: true }
)

const isEmpty = computed(() => {
  return inputTxt.value === ""
})

const emit = defineEmits(["update:modelValue"])
const richText = computed(() => inputTxt.value.replace(/(<([^>]+)>)/gi, ""))
watch(
  () => inputTxt.value,
  (val) => {
    if (props.isRichText) emit("update:modelValue", val)
  }
)
</script>

<style scoped>
.sideBarOptionsBox {
  padding: 0px 16px;
}
.placeholder,
.editor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor.show {
  opacity: 1;
}

.editor.hide {
  opacity: 0;
  height: 0;
}

.input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left {
  flex: 1;
  overflow: hidden;
}
</style>
