<template>
  <div
    :class="[isEditing ? 'onEditing' : '']"
    class="sideBarOptionsBox"
    @click="handleClick"
    @keyup.enter="handleClick"
    tabindex="0"
  >
    <div v-if="!isEditing" class="overview">
      <div class="left">
        <div :class="['title', { placeholding: isEmpty }]">
          {{ props.overviewContent.title }}
        </div>
        <div class="description">{{ props.overviewContent.description }}</div>
      </div>
      <div class="right">
        <div class="icon rfi_pen rfiBlack"></div>
      </div>
    </div>
    <div v-else class="context">
      <div class="items">
        <slot name="items"></slot>
      </div>
      <div v-show="hasMessage" class="messageBox" style="margin-top: 16px">
        <div v-show="hasUnsavedChange" class="message">有編輯尚未儲存</div>
        <div v-show="hasFormUnfilled" class="message">有欄位尚未填寫</div>
      </div>
      <div class="buttonBox">
        <ButtonMini
          context="刪除"
          type="secondary"
          length="extend"
          @click.stop="emit('delete')"
        />
        <ButtonMini
          context="儲存"
          type="primary"
          length="extend"
          @click.stop="emit('save')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue"
import ButtonMini from "./ButtonMini.vue"

const emit = defineEmits(["expand", "save", "delete"])
const props = defineProps({
  overviewContent: {
    type: Object,
    default: () => {
      return {
        key: "",
        title: "",
        description: "",
      }
    },
  },
  current: {
    type: Number,
    default: 0,
  },
  hasUnsavedChange: {
    type: Boolean,
    default: false,
  },
  hasFormUnfilled: {
    type: Boolean,
    default: false,
  },
})

const isEmpty = computed(() => props.overviewContent.description === "")
const hasMessage = computed(() => {
  return props.hasUnsavedChange || props.hasFormUnfilled
})

const isEditing = computed(() => props.current === props.overviewContent.key)
const handleClick = () => {
  emit("expand", props.overviewContent.description)
}
</script>

<style scoped>
.overview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  width: 100%;
}

.description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.context .buttonBox {
  display: flex;
  margin-top: 16px;
}

.context .buttonBox .secondary {
  margin-right: 8px;
}
</style>
