<template>
  <div class="sideBarOptionsBox">
    <div class="title">{{ props.itemName }}</div>
    <div v-show="hasMessage" class="messageBox">
      <div v-show="isUploading" class="message">正在上傳⋯⋯</div>
      <div v-show="hasError" class="message">發生錯誤，請再試一次。</div>
    </div>
    <div v-show="!isUploaded" class="uploader">
      <label class="custom" for="customButton">
        <ButtonMini
          id="customButton"
          context="上傳圖片"
          type="secondary"
          length="extend"
          :disabled="isUploading"
        />
        <input
          ref="imageUploader"
          id="customButton"
          type="file"
          accept="image/png,image/jpeg,image/jpg"
          @change="uploadImage($event)"
        />
      </label>
    </div>
    <div v-show="isUploaded" class="preview">
      <div class="image">
        <img :src="uploadedImage" />
      </div>
      <input type="file" id="imgupload" style="display: none" />
      <ButtonMini
        context="移除"
        type="secondary"
        length="extend"
        @click="removeUploadedImage()"
        class="button secondary"
      />
    </div>
  </div>
  <SideBarItemDetail v-if="description" :description="props.description" />
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from "vue"
import ButtonMini from "./ButtonMini.vue"
import SideBarItemDetail from "./SideBarItemDetail.vue"
import axios from "axios"

let imageUploader = ref(null)

let hasMessage = computed(() => !!isUploading.value || !!hasError.value)

let hasError = ref(false)
let isUploading = ref(false)
let isUploaded = computed(() => !!uploadedImage.value)

const uploadedImage = ref("")
if (props.image) uploadedImage.value = props.image

const props = defineProps({
  itemName: {
    type: String,
    default: "圖片",
  },
  image: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
})
const emits = defineEmits(["update:image"])

const removeUploadedImage = () => {
  imageUploader.value.value = ""
  uploadedImage.value = ""
}

const uploadImage = (event) => {
  if (event) {
    var form = new FormData()
    form.append("image", event.target.files[0])
    form.append("type", "file")

    const settings = {
      url: "https://api.imgur.com/3/image",
      method: "POST",
      headers: {
        Authorization: "Client-ID addc2e59cc8ae36",
      },
      data: form,
    }

    isUploading.value = true

    axios(settings)
      .then(function (response) {
        uploadedImage.value = response.link
        emits("update:image", uploadedImage.value)
        isUploading.value = false
      })
      .catch(function () {
        hasError.value = true
        isUploading.value = false
      })
  }
}
</script>

<style scoped>
.sideBarOptionsBox > div:not(:last-child) {
  margin-bottom: 8px;
}
.button {
  width: 100%;
}
.title {
  margin-bottom: 8px;
}

.image {
  border: 1px solid var(--borderNormal);
  padding: 14px;
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  background: #eeeeee;
}

.image img {
  max-height: 200px;
}

.uploader {
  position: relative;
}

.uploader .custom > input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
</style>
