<template>
  <div class="box">
    <div class="notificationCenter appBarItem">
      <Menu as="div" class="">
        <div>
          <MenuButton class="appBarButton">
            <div
              :class="hasUnreadNotification ? 'hasUnread' : ''"
              class="notificationCenterButton icon rfs_object_bell_normal"
            ></div>
          </MenuButton>
        </div>
        <MenuItems class="menuBox">
          <div class="menuHeader">
            <div class="content">
              <div class="title">通知</div>
              <div class="target" @click="readAll">全部標為已讀取</div>
            </div>
          </div>
          <div class="menuOptions">
            <LoadingSpinner v-if="isLoading" />
            <div class="messages" v-if="noNotification">
              <h1>目前沒有通知</h1>
              <h1 class="sub">當您的網站有新的動態時，通知將會顯示在這裡</h1>
            </div>
            <MenuItem v-for="(item, index) in notification" :key="`${item.subject}-${index}`">
              <AppBarNotification
                :read="item.readed"
                :subject="transformSubject(item)"
                :type="item.type"
                :time="$dayjs(item.time).fromNow()"
                @click="handleClick(item)"
              />
            </MenuItem>
            <MenuItem>
              <div class="blank">沒有更多通知了</div>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
    <div class="profileMenu appBarItem">
      <Menu as="div" class="">
        <div>
          <MenuButton class="appBarButton">
            <img class="profilePic" :src="userProfilePic"  @error="defaultImg" loading="eager" />
          </MenuButton>
        </div>
        <MenuItems class="menuBox">
          <div class="menuHeader">
            <div class="content">
              <div class="title">{{ userName }}</div>
              <!-- <div class="target">我的赤狐帳戶</div> -->
            </div>
          </div>
          <div class="menuOptions">
            <!-- <MenuItem v-slot="{ active }">
              <button :class="[active ? 'active' : 'normal', 'items']">
                <div class="icon rfs_object_lifebuoy"></div>
                <div class="text">說明中心與尋求支援</div>
              </button>
            </MenuItem> -->
            <MenuItem v-slot="{ active }">
              <button
                :class="[active ? 'active' : 'normal', 'items']"
                @click="logout"
              >
                <div class="icon rfs_symbol_xmark"></div>
                <div class="text">登出</div>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { computed, ref, inject } from "vue"
import { storeToRefs } from "pinia"
import { useInfoStore } from "@/store/info"
import { useLogout } from "@/composables/useLogout"
import { useLoading } from "@/composables/useLoading"
import { useRouter } from "vue-router"
import LoadingSpinner from "@/components/LoadingSpinner"
import AppBarNotification from "@/components/AppBarNotification"
import errorImg from '@/assets/temp/img_defaultProfilePic.jpg'
import 'dayjs/locale/zh-tw'

const $http = inject("http")
const router = useRouter()
const { logout } = useLogout()
const { load, unload, isLoading } = useLoading()
const infoStore = useInfoStore()
const { userInfo } = storeToRefs(infoStore)
const userProfilePic = computed(() => userInfo.value.imageRound || errorImg)
const userName = computed(() => userInfo.value.userName || "")

const defaultImg = e => e.target.src = errorImg

// 通知中心時間格式採中文處理
const $dayjs = inject("dayjs")
$dayjs.locale('zh-tw')

const notification = ref([])
const hasUnreadNotification = computed(() => notification.value.some(item => item.readed === false))
const noNotification = ref(false)
const transformSubject = (item) => {
  let str = ''

  switch (item.type) {
    case 'newComment':
      str = `｢${item.subject}｣ 有一則新留言`
      break
    default:
      break
  }

  return str
}
// 取得通知中心資料
const getNotification = async () => {
  load()
  await $http
    .get("/notify")
      .then((res) => {
        notification.value = res
        if (res.length === 0) noNotification.value = true
      })
      .catch((err) => {
        console.error(err)
      })
  unload()
}
getNotification()

const readAll = async () => {
  if (hasUnreadNotification.value) {
    load()
  
    await $http
      .post("/notify/clear")
        .then(() => {
          getNotification()
        })
        .catch((err) => {
          console.error(err)
        })
  
    unload()
  }
}

const handleClick = (item) => {
  if (!item.readed) readSingle(Number(item.notifyId))
  router.push({ name: 'Publishing', params: { id: item.target } })
}
const readSingle = async (notifyId) => {
  load()

  await $http
    .post("/notify/read", {
      params: {
        notifyId,
      }
    })
      .then(() => {
        getNotification()
      })
      .catch((err) => {
        console.error(err)
      })

  unload()
}
</script>

<style scoped>
.box {
  height: 88px;
  background: var(--filledNormal);
  box-shadow: inset 0 -1px 0 0 var(--borderNormal);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 24px;
  position: relative;
  z-index: 100;
}

.appBarItem {
  position: relative;
}

.appBarItem:not(:last-child) {
  margin-right: 12px;
}

.appBarButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  position: relative;
}

.appBarButton:last-child {
  margin-right: 0px;
}

.appBarButton:hover::before {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  background: var(--filledHover);
  border-radius: 100%;
  position: absolute;
  z-index: -1;
}

.notificationCenterButton {
  width: 28px;
  height: 28px;
  display: block;
  border-radius: 100%;
  position: relative;
}

.notificationCenterButton.hasUnread::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 6px;
  height: 6px;
  background: var(--theme);
  /* background: #ff0000; */
  border-radius: 100%;
}

.profilePic {
  width: 40px;
  height: 40px;
  background: url("~@/assets/temp/img_defaultProfilePic.jpg");
  background-size: cover;
  border-radius: 100%;
  overflow: hidden;
}

.notificationCenter .menuBox {
  height: calc(100vh - 90px);
  max-height: 500px;
  overflow: scroll;
}

.notificationCenter .blank {
  height: 100px;
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.notificationCenter .messages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.menuBox {
  position: absolute;
  right: 0;
  margin-top: 8px;
  width: 380px;
  transform-origin: top right;
  background: var(--filledNormal);
  border: 1px solid var(--borderNormal);
  box-shadow: 0 13px 28px -8px rgba(0, 0, 0, 0.15),
    0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.menuHeader {
  background: var(--filledNormal);
  box-shadow: inset 0 -1px 0 0 var(--borderNormal);
}

.menuHeader .content {
  display: flex;
  padding: 20px 24px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}

.menuHeader .content .title {
  font-size: 18px;
  font-weight: bold;
}

.menuHeader .content .target {
  font-size: 14px;
  color: var(--theme);
  font-weight: 600;
}

.menuOptions {
  box-sizing: border-box;
  padding: 12px;
  flex: 1;
  overflow: scroll;
}

.menuOptions .items {
  font-size: 17px;
  width: 100%;
  text-align: left;
  border-radius: 6px;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menuOptions .items {
  color: var(--primaryTextColor);
  background: var(--filledNormal);
}

.menuOptions .items:hover {
  background: var(--filledHover);
}

/* .menuOptions .items.active {
  color: var(--primaryTextColor);
  background: var(--filledHover)
} */

.profileMenu .icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 12px;
}

.profileMenu .items .text {
  font-weight: 500;
}
</style>
