import { defineStore } from 'pinia'

export const useInfoStore = defineStore({
  id: 'info',
  state: () => ({
    userInfo:{
      userId: '',
      userEnName: '',
      userName: '',
      imageRound: '',
    },
  }),

  actions: {
    setUserInfo({ userId, userEnName, userName, imageRound }) {
      this.userInfo = { userId, userEnName, userName, imageRound }
    },
  },
})