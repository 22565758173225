export default function (errorCode) {
  let errorMsg = ''

  switch (errorCode) {
    case '404':
      errorMsg = '服務錯誤'
      break
    case '401':
      errorMsg = '請重新登入'
      break
    default:
      errorMsg = '請稍後再試'
      break
  }

  return errorMsg
}