<template>
  <div class="contentContainer">
    <div class="contentBox">
      <div class="optionsBox">
        <div class="left">
          <Button
            context="撰寫新文章"
            type="primary"
            length="extend"
            @click="handleIsEdit('create')"
          />
        </div>
        <div class="right">
          <DropDownSelect
            :options="tagsList"
            :defaultItem="{ value: 0, label: '全部' }"
            v-model:selected-item="selectedTag"
          />
          <SearchBar v-model="searchTxt" />
        </div>
      </div>
      <div class="content">
        <LoadingSpinner class="loading" v-if="isLoading" />
        <template v-else>
          <PublishingArticle
            v-show="true"
            v-for="article in articleList"
            class="posts"
            :key="article.id"
            :content="article"
            @click="setPostDetail(article)"
            @keyup.enter="setPostDetail(article)"
          />
          <div class="blank" v-show="isAllLoad">沒有更多文章了</div>
          <div v-if="noPosts" class="publishingBlankStatus">
            <div v-if="searchTxt" class="messages">
              <h1>找不到結果</h1>
              <h1 class="sub">沒有文章符合搜尋的內容</h1>
            </div>
            <div v-else class="messages">
              <h1>沒有文章</h1>
              <h1 class="sub">點選「撰寫新文章」來抒發想法！</h1>
            </div>
          </div>
        </template>
      </div>
      <LoadingSpinner class="loading" v-if="loadLoading.isLoading.value" />
      <div ref="observerArticle"></div>
    </div>
    <div class="sideBar">
      <SideBar
        primaryButton="編輯"
        secondaryButton="在網站中檢視"
        :emptyStates="emptyStates"
        :isInteracting="hasIdFromParam"
        @primaryClick="handleIsEdit('edit')"
        @secondaryClick="visitArticle(postDetail.id)"
      >
        <template v-slot:SideBarItems>
          <LoadingSpinner
            class="loading"
            v-if="detailLoading.isLoading.value && isAllLoading"
          />
          <SideBarPublishingPanel
            v-else
            :content="postDetail"
            :loading="detailLoading.isLoading.value"
            :errorCommentId="errorCommentId"
            @removeComment="handleRemoveComment"
            @updateComment="updateComment"
            @replyComment="replyComment"
          />
        </template>
      </SideBar>
    </div>
  </div>
  <div v-if="isEdit" class="rich-editor">
    <RichEditor
      v-model:isEdit="isEdit"
      :complexContent="articleContent"
      :articleId="articleId"
      type="complex"
      @submit="handleSubmit"
      @delete="handleDelete"
    />
  </div>
</template>

<script setup>
import { useDialogStore } from "@/store/dialog"
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import SideBar from "../components/SideBar.vue"
import SearchBar from "../components/SearchBar.vue"
import Button from "../components/Button.vue"
import DropDownSelect from "../components/DropDownSelect.vue"
import SideBarPublishingPanel from "../components/SideBarPublishingPanel.vue"
import PublishingArticle from "../components/PublishingArticle.vue"
import LoadingSpinner from "../components/LoadingSpinner.vue"
import RichEditor from "@/components/RichEditor.vue"

import { ref, inject, onBeforeMount, onMounted, watch, computed } from "vue"
import { useLoading } from "@/composables/useLoading"

const noPosts = ref(false)

const emptyStates = {
  header: "檢視統計資料、留言與編輯文章",
  message: "選擇一篇文章來進行",
}

const { load, unload, isLoading } = useLoading()
const $http = inject("http")
const articleList = ref([])
const storeArticles = ref([])
const totalCount = ref(0)
const searchTxt = ref("")
const selectedTag = ref({})
const pagination = ref({
  page: 1,
  count: 6,
})

// 專欄是否全載入
const isAllLoad = computed(() => {
  return articleList.value.length !== 0
    ? articleList.value.length === totalCount.value
    : false
})

const userId = localStorage.getItem("userId")
const articleParams = computed(() => {
  return {
    page: pagination.value.page,
    count: pagination.value.count,
    user: userId,
    title: searchTxt.value,
    tag: selectedTag.value.label === "全部" ? "" : selectedTag.value.label,
    isEdit: true,
  }
})

// 取得標籤列表
const tagsList = ref([])
const getTagsList = async () => {
  await $http
    .get("/articles/allTags")
    .then((res) => {
      tagsList.value = [
        { value: 0, label: "全部" },
        ...res.map((tag, index) => ({ value: index + 1, label: tag })),
      ]
    })
    .catch((err) => {
      console.error(err)
    })
}

// 取得專欄列表
const getArticles = async () => {
  load()
  await $http
    .get("/articles", {
      params: articleParams.value,
    })
    .then((res) => {
      articleList.value = res.articles
      storeArticles.value = articleList.value
      totalCount.value = res.totalCount
      noPosts.value = res.articles.length === 0
    })
  unload()
}
onBeforeMount(() => {
  getArticles()
  getTagsList()
})

watch(
  () => [searchTxt.value, selectedTag.value],
  () => reloadArticles()
)
const reloadArticles = () => {
  pagination.value.page = 1
  pagination.value.count = 6
  noPosts.value = false
  getArticles()
}

// 專欄無限載入
// IntersectionObserver的api會使元素進入視窗就觸發
onMounted(() => articleScrollListener())
const observerArticle = ref(null)
const articleScrollListener = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  }
  const callback = (entries) => {
    entries.forEach((entry) => {
      if (!isLoading.value && entry.isIntersecting) {
        if (articleList.value.length < totalCount.value) {
          pagination.value.page++

          loadArticle()
        }
      }
    })
  }

  const observer = new IntersectionObserver(callback, options)
  observer.observe(observerArticle.value)
}
const loadLoading = useLoading()
const loadArticle = async () => {
  loadLoading.load()
  await $http
    .get("/articles", {
      params: articleParams.value,
    })
    .then((res) => {
      articleList.value = [...storeArticles.value, ...res.articles]
      storeArticles.value = articleList.value
    })
  loadLoading.unload()
}

// 取得單篇專欄的資料
const route = useRoute()
const router = useRouter()
const hasIdFromParam = computed(() => !!Number(route.params.id))

const postDetail = ref()
const isAllLoading = ref(true)
const detailLoading = useLoading()
const setPostDetail = (source) => {
  if (source.id !== Number(route.params.id)) {
    postDetail.value = source
    isAllLoading.value = false
    router.push({ name: "Publishing", params: { id: source.id } })
  }
}
const getArticleDetail = async (id) => {
  detailLoading.load()

  await $http
    .get("/articles", {
      params: {
        id,
      },
    })
    .then((res) => {
      if (res.id === Number(route.params.id)) {
        postDetail.value = res
        articleContent.value = {
          title: res.title,
          subTitle: res.subTitle,
          body: res.body,
          hero: res.hero,
          thumbnail: res.thumbnail,
          tags: res.tags,
          description: res.description,
          status: res.status,
        }

        isAllLoading.value = false
      }
    })

  detailLoading.unload()
}
watch(
  () => route.params.id,
  async (id) => {
    await getArticleDetail(id)
    isAllLoading.value = true
  },
  { immediate: true }
)

// 刪除單篇專欄裡的某則留言或是回覆
const dialogStore = useDialogStore()
const { actions } = storeToRefs(dialogStore)
const removeCommentId = ref(undefined)
const removeReplyId = ref(undefined)
const handleRemoveComment = (type, id) => {
  const typeName = {
    comment: "留言",
    reply: "回覆",
  }

  removeCommentId.value = type === "comment" ? id : undefined
  removeReplyId.value = type === "reply" ? id : undefined

  dialogStore.set({
    title: `刪除${typeName[type]}`,
    supportingText: `確定要刪除這則${typeName[type]}嗎？已刪除的${typeName[type]}無法復原。`,
    primaryButton: "確定",
    secondaryButton: "取消",
  })
}
const removeComment = async () => {
  isAllLoading.value = false

  detailLoading.load()
  await $http
    .delete("/articles/comment", {
      params: {
        commentId: removeCommentId.value,
      },
    })
    .then(() => {
      getArticleDetail(route.params.id)
    })
    .catch(() => {})
    .finally(() => {
      removeCommentId.value = undefined
      dialogStore.action("")
    })
  detailLoading.unload()
}
const removeReplayComment = async () => {
  isAllLoading.value = false

  detailLoading.load()
  await $http
    .delete("/articles/comment", {
      params: {
        commentId: removeReplyId.value,
      },
    })
    .then(() => {
      getArticleDetail(route.params.id)
    })
    .catch(() => {})
    .finally(() => {
      removeReplyId.value = undefined
      dialogStore.action("")
    })
  detailLoading.unload()
}
// 更新、新增單篇專欄裡的某則回覆留言
const errorCommentId = ref("")
const updateComment = async (commentId, content) => {
  isAllLoading.value = false

  detailLoading.load()
  await $http
    .put("/articles/comment", {
      data: {
        ...content,
      },
    })
    .then(() => {
      errorCommentId.value = ""
      getArticleDetail(route.params.id)
    })
    .catch(() => {
      errorCommentId.value = commentId
    })
  detailLoading.unload()
}
const replyComment = async (commentId, content) => {
  isAllLoading.value = false

  detailLoading.load()
  await $http
    .post("/articles/comment", {
      params: {
        id: Number(route.params.id),
      },
      data: {
        ...content,
      },
    })
    .then(() => {
      errorCommentId.value = ""
      getArticleDetail(route.params.id)
    })
    .catch(() => {
      errorCommentId.value = commentId
    })
  detailLoading.unload()
}

const isEdit = ref(false)
const articleId = ref(undefined)
const handleIsEdit = (action = undefined) => {
  switch (action) {
    case "create":
      articleContent.value = {}
      articleId.value = undefined
      break
    case "edit":
      articleId.value = postDetail.value.id
      break
    default:
      break
  }

  isEdit.value = !isEdit.value
}

const visitArticle = (id) => {
  window.open("https://nutrition.tw/article/" + id, "_blank").focus()
}

// 新增、更新單篇專欄
const articleContent = ref({})
const handleSubmit = (isEditingExist, content) => {
  isEditingExist ? updateArticle(content) : createArticle(content)
}
const createArticle = async (content) => {
  await $http
    .post("/articles", {
      data: content,
    })
    .then(() => {
      reloadArticles()
    })
    .catch((err) => {
      console.error(err)
    })
}
const updateArticle = async (content) => {
  await $http
    .put("/articles", {
      params: { id: articleId.value },
      data: content,
    })
    .then(() => {
      reloadArticles()
      getArticleDetail(articleId.value)
    })
    .catch((err) => {
      console.error(err)
    })
}

// 刪除單篇專欄
const handleDelete = () => {
  dialogStore.set({
    title: "刪除文章",
    supportingText: "確定要刪除這篇文章嗎？已刪除的文章無法復原。",
    primaryButton: "確定",
    secondaryButton: "取消",
  })
}
const deleteArticle = async () => {
  await $http
    .delete("/articles", {
      params: { id: articleId.value },
    })
    .then(() => {
      reloadArticles()
      postDetail.value = undefined
      articleId.value = undefined
      articleContent.value = {}
      router.push({ name: "Publishing" })
    })
    .catch((err) => {
      console.error(err)
    })
    .finally(() => {
      dialogStore.action("")
    })
}
watch(
  () => actions.value,
  (val) => {
    switch (val) {
      case "刪除留言":
        removeComment()
        break
      case "刪除回覆":
        removeReplayComment()
        break
      case "刪除文章":
        handleIsEdit()
        deleteArticle()
        break
      default:
        removeCommentId.value = undefined
        removeReplyId.value = undefined
        break
    }
  }
)
</script>

<style scoped>
.optionsBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.right {
  display: flex;
}

.right > div {
  margin-left: 8px;
}

.posts:not(last-child) {
  margin-bottom: 8px;
}

.loading {
  margin: 12px 0;
}

/* .content {
  height: 100%;
} */

.publishingBlankStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.blank {
  height: 100px;
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}
</style>
