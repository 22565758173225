import { createApp } from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import router from "./router"
import "@/router/check-router"
import "./index.css"
import "./assets/style.css"
import axios from "./service/axios"
import http from "./service/http"

import dayjs from "dayjs"
import calendar from "dayjs/plugin/calendar.js"
import relativeTime from "dayjs/plugin/relativeTime.js"
import updateLocale from "dayjs/plugin/updateLocale.js"

dayjs.extend(calendar)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

const app = createApp(App)
const pinia = createPinia()

axios()
app.provide("http", http)
app.provide("dayjs", dayjs) //全>>>>域<<<<註冊用 dayjs 這個名字讓任何地方都能被使用 ＸＤ 好～
app.use(pinia)
app.use(router).mount("#app")
