<template>
  <div class="loginContainer">
    <div class="window">
      <div class="header">
        <div class="logo"></div>
        <div class="title">登入赤狐通</div>
      </div>
      <div v-show="hasMessage" class="messageBox" style="margin-bottom: 16px">
        <div v-show="informationIncorrect" class="message">
          <h1>電子郵件或密碼錯誤</h1>
        </div>
        <div v-show="hasError" class="message">
          <h1>出了一點問題，請稍候再試一次</h1>
        </div>
        <p>
          如果您在登入上遇到困難，請向您專案的赤狐代表或專案經理尋求協助。如果您不清楚誰該向誰尋求幫助，您也可以致信到
          <a href="mailto:den@redfoxes.tw">den@redfoxes.tw</a
          >，我們會以最快的速度向您提供協助。
        </p>
      </div>
      <div class="form">
        <div class="fields">
          <SideBarText
            v-model="email"
            itemName="電子郵件"
            :text="email"
            :autoFocus="true"
            placeholder="sprigatito@redfoxes.tw"
            type="email"
            autocomplete="username"
            @onPressedEnter="login"
          />
          <SideBarText
            v-model="password"
            itemName="密碼"
            :text="password"
            placeholder="······"
            type="password"
            autocomplete="password"
            @onPressedEnter="login"
          />
        </div>
        <div class="button">
          <Button
            context="登入"
            type="primary"
            length="extend"
            :disabled="isLoading"
            @click="login"
          />
        </div>
      </div>
      <div class="divider"></div>
      <div class="footer">
        <!-- <div class="left">需要協助？</div> -->
        <div class="right">
          <span>赤狐 © {{ year }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/Button.vue"
import SideBarText from "@/components/SideBarText.vue"
import { computed, ref, inject } from "vue"
import { useRouter } from "vue-router"
import { useLoading } from "@/composables/useLoading"

const { load, unload, isLoading } = useLoading()
const router = useRouter()
const year = new Date().getFullYear()

const informationIncorrect = ref(false)
const hasError = ref(false)
const hasMessage = computed(() => hasError.value || informationIncorrect.value)

const email = ref("")
const password = ref("")
const $http = inject("http")
const login = async () => {
  load()
  await $http
    .post("/users/login", {
      data: {
        userName: email.value,
        password: password.value,
      },
    })
    .then((res) => {
      if (Object.keys(res).length !== 0) {
        hasError.value = false
        informationIncorrect.value = false

        localStorage.setItem("token", res.token)
        localStorage.setItem("userId", res.userId)
        router.push({ name: "Page" })
      } else {
        informationIncorrect.value = true
      }
    })
    .catch((err) => {
      informationIncorrect.value = err.code === 400 // 帳密錯誤
      hasError.value = err.code !== 400 // 服務錯誤
    })
  unload()
}
</script>

<style scoped>
.loginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.window {
  width: 420px;
  height: auto;
  display: flex;
  box-shadow: inset 0 0 0 1px var(--borderNormal);
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
}

.logo {
  background: url("~@/assets/img_redFoxes_logo.png");
  background-size: cover;
  height: 40px;
  width: 33px;
  display: block;
  margin-bottom: 48px;
}

.header .title {
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
}

.messageBox h1 {
  font-size: 17px;
  font-weight: 500;
}
.messageBox p {
  font-size: 14px;
  margin-top: 4px;
  line-height: 140%;
}

.header,
.fields {
  margin-bottom: 24px;
}

.form {
  width: 100%;
}

.fields {
  width: 100%;
  display: block;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--borderNormal);
  margin: 24px 0px;
}

.footer {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.footer .right {
  color: var(--secondaryText);
}
</style>
