<template>
  <div class="contentContainer">
    <div class="contentBox">
      <div class="content">
        <ContentCollapsibleList :title="name">
          <template v-slot:ContentCollapsibleListItems>
            <ContentCollapsibleListItems
              title="基本資訊"
              @click="sideBarLoader('基本資訊', Information, 'save', false)"
              @keyup.enter="
                sideBarLoader('基本資訊', Information, 'save', false)
              "
              :active="isActive('基本資訊')"
            />
            <ContentCollapsibleListItems
              title="技能"
              @click="sideBarLoader('技能', Skills, 'add')"
              @keyup.enter="sideBarLoader('技能', Skills, 'add')"
              :active="isActive('技能')"
            />
            <ContentCollapsibleListItems
              title="經歷"
              @click="sideBarLoader('經歷', Experiences, 'add')"
              @keyup.enter="sideBarLoader('經歷', Experiences, 'add')"
              :active="isActive('經歷')"
            />
            <ContentCollapsibleListItems
              title="證照"
              @click="sideBarLoader('證照', Certificate, 'add')"
              @keyup.enter="sideBarLoader('證照', Certificate, 'add')"
              :active="isActive('證照')"
            />
          </template>
        </ContentCollapsibleList>
      </div>
    </div>
    <div class="sideBar">
      <SideBar
        :title="sideBarTitle"
        state="正在編輯"
        :emptyStates="emptyStates"
        :isInteracting="!!sideBarTitle"
        :primaryButton="sideBarButton === 'save' ? '儲存' : '新增'"
        @primaryClick="handleClick"
        :isReordering="isReordering"
        :reorderable="sideBarReorderable"
        @reorderingClick="isReordering = !isReordering"
      >
        <template v-slot:SideBarItems>
          <LoadingSpinner v-if="isLoading" />
          <Information
            v-else-if="isInformationComp"
            v-model:userInfo="basicProfile"
          />
          <component
            v-else-if="!!sideBarCollection"
            :is="sideBarCollection"
            :count="sideBarItemCount"
            :isReordering="isReordering"
          />
        </template>
      </SideBar>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, inject } from "vue"
import { storeToRefs } from "pinia"
import { useInfoStore } from "@/store/info"
import { useLoading } from "@/composables/useLoading"
import { useMessageStore } from "@/store/message"
import ContentCollapsibleList from "@/components/ContentCollapsibleList.vue"
import ContentCollapsibleListItems from "@/components/ContentCollapsibleListItems.vue"
import SideBar from "@/components/SideBar.vue"
import Information from "@/components/SideBarCollections/Profile/Information"
import Skills from "../components/SideBarCollections/Profile/Skills.vue"
import Experiences from "../components/SideBarCollections/Profile/Experiences.vue"
import Certificate from "../components/SideBarCollections/Profile/Certificates.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"

const $http = inject("http")
const emptyStates = {
  header: "編輯個人資料",
  message: "選擇一個分類來編輯",
}

const messageStore = useMessageStore()

let sideBarTitle = ref("")
let sideBarCollection = ""
let sideBarButton = ref("")
let sideBarReorderable = ref()

const infoStore = useInfoStore()
const { userInfo } = storeToRefs(infoStore)
const name = computed(() => userInfo.value.userName)

const sideBarItemCount = ref(0)
const isReordering = ref(false)
const sideBarLoader = (title, component, btnType, isReorderable) => {
  sideBarCollection = component
  sideBarTitle.value = title
  sideBarButton.value = btnType
  isReordering.value = false
  sideBarReorderable.value = isReorderable
}
const isInformationComp = computed(() => {
  return sideBarTitle.value === "基本資訊" && !!sideBarCollection
})
const isActive = (type) => {
  return type === sideBarTitle.value
}

const basicProfile = ref({})
const handleClick = () => {
  return isInformationComp.value ? editInformation() : sideBarItemCount.value++
}

// 編輯營養師的資料
const { load, unload, isLoading } = useLoading()
const editInformation = async () => {
  load()
  await $http
    .put("/about/user", {
      data: {
        ...basicProfile.value,
      },
    })
    .then(() => {
      messageStore.set({ msg: "已儲存", ctaText: "確定" })
    })
    .catch((err) => {
      console.error(err)
    })
  unload()
}
</script>

<style scoped></style>
