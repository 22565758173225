<template>
  <LoadingSpinner v-if="isLoading" />
  <div v-show="!isReordering && !isLoading" style="height: 100%">
    <SideBarGroup
      v-for="item in items"
      :key="item.place"
      :overviewContent="getOverviewContent(item)"
      :current="editingItem.id"
      @expand="handleExpand(item)"
      @save="handleSave(item.place)"
      @delete="handleDelete(item.place)"
    >
      <template #items>
        <SideBarListBox
          v-model="selectedTag"
          itemName="標籤"
          :option="labels"
          :value="selectedTag"
        />
      </template>
    </SideBarGroup>
    <div v-if="items.length == 0" class="sideBarEmptyState">
      <!-- <div class="illustration">
      <img :src="props.emptyStates.illustration" width="100" height="100" />
    </div> -->
      <div class="messages">
        <h1>空無一物</h1>
        <h1 class="sub">請點選「新增」來開始指定。</h1>
      </div>
    </div>
  </div>
  <SideBarReordering
    v-show="isReordering"
    :items="items"
    label="value"
    sort="place"
  />
</template>

<script setup>
import { ref, defineProps, watch, inject, onBeforeMount, computed } from "vue"
import { useLoading } from "@/composables/useLoading"
import { storeToRefs } from "pinia"
import { useStashStore } from "@/store/stash"
import { useMessageStore } from "@/store/message"
import SideBarGroup from "../../SideBarGroup.vue"
import SideBarListBox from "../../SideBarListBox.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import SideBarReordering from "../../SideBarReordering.vue"

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  isReordering: {
    type: Boolean,
    default: false,
  },
})

const items = ref([])
const labels = ref([])

const stashStore = useStashStore()
const messageStore = useMessageStore()
const { data } = storeToRefs(stashStore)
const { actions } = storeToRefs(messageStore)

watch(
  () => props.count,
  (val) => {
    if (val > 0 && items.value.length + 1 <= 8) {
      const newItem = {
        place: items.value.length + 1,
        value: "",
      }
      items.value.push(newItem)
      handleExpand(newItem)
    }
  }
)

const editingItem = ref({
  id: -1,
  value: "",
})
const handleExpand = (item) => {
  editingItem.value = { id: item.place, value: item.value }
}
const getOverviewContent = (item) => {
  return {
    key: item.place,
    title: `分類 ${item.place}`,
    description: item.value,
  }
}

const selectedTag = ref({})

// 儲存
const handleSave = (index) => {
  return hasChanged.value ? checkCourse(index) : handleExpand("", -1)
}
const checkCourse = (index) => {
  setArticleLabelData("edit", index)
  editArticleLabel()
}
const hasChanged = computed(
  () => selectedTag.value.value !== editingItem.value.value
)

// 更新順序
watch(
  () => props.isReordering,
  () => {
    if (!props.isReordering) {
      emitAction.value = "edit"
      editArticleLabel()
    }
  }
)

// 刪除
const stashItems = ref([])
const handleDelete = (index) => {
  if (items.value[index] === "") {
    items.value.splice(index, 1)
  } else {
    setArticleLabelData("delete", index)
    stashStore.stash(stashItems.value)
    editArticleLabel()
  }
}

const emitAction = ref("")
const setArticleLabelData = (action, index) => {
  switch (action) {
    case "edit":
      items.value[index - 1].value = selectedTag.value.value
      emitAction.value = "edit"
      break
    case "delete":
      stashItems.value = JSON.parse(JSON.stringify(items.value)).filter(
        (item) => item !== ""
      )
      items.value.splice(index - 1, 1)
      emitAction.value = "delete"
      break
  }

  items.value = items.value.filter((item) => item !== "")
}
watch(
  () => actions.value,
  (val) => {
    if (val === "復原") {
      items.value = data.value
      emitAction.value = ""
      editArticleLabel()
      messageStore.action("")
      stashStore.stash([])
    }
  }
)

// 取得標籤列表
const getTagsList = async () => {
  load()
  await $http
    .get("/articles/allTags")
    .then((res) => {
      labels.value = [
        ...res.map((tag, index) => ({ id: index + 1, value: tag })),
      ]
    })
    .catch((err) => {
      console.error(err)
    })
  unload()
}

// 取得專欄分類資料
const $http = inject("http")
const { load, unload, isLoading } = useLoading()
const getArticleLabel = async () => {
  load()
  await $http
    .get("/articles")
    .then((res) => {
      items.value = res.topTags.map((tag, index) => ({
        place: index + 1,
        value: tag,
      }))
    })
    .catch((err) => {
      console.error(err)
    })
  unload()
}
onBeforeMount(() => {
  getArticleLabel()
  getTagsList()
})

// 編輯、新增、刪除專欄分類資料
const editArticleLabel = async () => {
  load()
  await $http
    .put("/about", {
      params: {
        type: "topTags",
      },
      data: items.value.map((item) => item.value),
    })
    .then(() => {
      handleExpand("", -1)
      handleMessage()
    })
    .finally(() => {
      getArticleLabel()
    })
  unload()
}
const handleMessage = () => {
  switch (emitAction.value) {
    case "delete":
      messageStore.set({ msg: "已刪除", ctaText: "復原" })
      break
    case "edit":
      messageStore.set({ msg: "已儲存", ctaText: "確定" })
      break
    default:
      messageStore.clear()
      break
  }
}

watch(
  () => editingItem.value.id,
  () => {
    selectedTag.value = editingItem.value
  },
  { immediate: true }
)
</script>
<style scoped></style>
