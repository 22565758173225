<template>
  <Listbox v-model="selectedOption">
    <div class="relative">
      <ListboxButton :class="[isPicking ? 'onActive' : '']" class="sideBarOptionsBox listBoxButton" @click="isPicking = !isPicking">
        <div class="left">
          <div :class="[!selectedOption.value ? 'placeholding' : '', 'title']">{{ props.itemName }}</div>
          <div class="value">{{ selectedOption.value }}</div>
        </div>
        <div class="right">
          <div class="icon rfs_symbol_chevron_down"></div>
        </div>
      </ListboxButton>
      <ListboxOptions class="listBoxOptions">
        <ListboxOption v-slot="{ active, selected }" v-for="option in props.option" :key="option.id" :value="option" as="template" @click="isPicking = false">
          <li :class="[active ? 'active' : selected || isSamePropsValue(option.value) ? 'selected' : 'normal', 'options']">
            <span>{{ option.value }}</span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";

const props = defineProps({
  itemName: String,
  option: Array,
  value: Object,
});

const isPicking = ref(false);
const selectedOption = ref(props.value.value === '' ? { id: 0, value: '請選擇'} : props.value );
const isSamePropsValue = (optionValue) => optionValue === props.value.value
</script>

<style scoped>
.listBoxOptions {
  position: absolute;
  box-shadow: var(--shadowMedium), inset 0 0 0 1px var(--borderNormal);
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 0px;
  padding: 8px;
  box-sizing: border-box;
  max-height: 190px;
  overflow: scroll;
  z-index: 100;
}

.listBoxButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.options {
  position: relative;
  padding: 8px;
  border-radius: 4px;
}

.options.active {
  background: var(--filledHover);
}

.options.selected {
  background: var(--filledActive);
  color: var(--theme);
}
</style>
