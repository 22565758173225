import router from "./index";

// 判斷有沒有登入
router.beforeEach((to, from, next)=>{
  const isLogin = !!localStorage.getItem('token') && !!localStorage.getItem('userId')

  if(isLogin){
    to.path === '/login' ? next('/') : next()
  } else {
    !['/login', '/newpassword'].includes(to.path) ? next('/login') : next()
  }
})