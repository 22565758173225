<template>
  <div class="loginContainer">
    <div class="window">
      <div class="header">
        <div class="logo"></div>
        <div class="title">設定新密碼</div>
      </div>
      <div v-show="hasMessage" class="messageBox" style="margin-bottom: 16px">
        <div v-show="hasError" class="message">
          <h1>出了一點問題，請稍候再試一次</h1>
          <p>
            如果問題一直出現，請向您專案的赤狐代表或專案經理尋求協助。如果您不清楚誰該向誰尋求幫助，您也可以致信到
            <a href="mailto:den@redfoxes.tw">den@redfoxes.tw</a
            >，我們會以最快的速度向您提供協助。
          </p>
        </div>
        <div v-show="isComplete" class="message">
          <h1>設定完成</h1>
          <p>
            您的新密碼已經完成設定，現在開始您可以使用新的密碼進行<a
              href="./login"
              >登入</a
            >。
          </p>
        </div>
      </div>
      <div class="form">
        <div class="fields">
          <SideBarText
            v-model="newPassword"
            itemName="新密碼"
            :text="newPassword"
            placeholder="······"
            type="password"
            autoFocus
          />
          <SideBarText
            v-model="confirmNewPassword"
            itemName="再次確認"
            :text="confirmNewPassword"
            placeholder="······"
            type="password"
          />
        </div>
        <div class="button">
          <Button
            context="完成"
            type="primary"
            length="extend"
            :disabled="!canLogin"
            @click="login"
          />
        </div>
      </div>
      <div class="divider"></div>
      <div class="footer">
        <!-- <div class="left">需要協助？</div> -->
        <div class="right">
          <span>赤狐 © {{ year }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/Button.vue"
import SideBarText from "@/components/SideBarText.vue"
import { computed, ref, inject } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useLoading } from "@/composables/useLoading"

localStorage.clear()

const $http = inject("http")
const router = useRouter()
const route = useRoute()
const { load, unload, isLoading } = useLoading()
const year = new Date().getFullYear()

const isComplete = ref(false)
const isSame = computed(() => newPassword.value === confirmNewPassword.value && ![newPassword.value, confirmNewPassword.value].includes(''))
const hasError = ref(false)
const hasMessage = computed(() => hasError.value || isComplete.value)
const canLogin = computed(() => isSame.value && !isLoading.value)

const newPassword = ref("")
const confirmNewPassword = ref("")
const spell = computed(() => route.query.spell)
hasError.value = !spell.value

// 完成
const login = async () => {
  load()
  await $http
    .put("/users/reset-password", {
      params: {
        spell: spell.value
      },
      data: {
        password: confirmNewPassword.value,
      },
    })
    .then(() => {
      isComplete.value = true
      setTimeout(() => {
        router.push({ name: 'Login' })
      }, 5000)
    })
    .catch(() => {
      hasError.value = true
    })
  unload()
}
</script>

<style scoped>
.loginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.window {
  width: 420px;
  height: auto;
  display: flex;
  box-shadow: inset 0 0 0 1px var(--borderNormal);
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
}

.logo {
  background: url("~@/assets/img_redFoxes_logo.png");
  background-size: cover;
  height: 40px;
  width: 33px;
  display: block;
  margin-bottom: 48px;
}

.header .title {
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
}

.messageBox h1 {
  font-size: 17px;
  font-weight: 500;
}
.messageBox p {
  font-size: 14px;
  margin-top: 4px;
  line-height: 140%;
}

.header,
.fields {
  margin-bottom: 24px;
}

.form {
  width: 100%;
}

.fields {
  width: 100%;
  display: block;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--borderNormal);
  margin: 24px 0px;
}

.footer {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.footer .right {
  color: var(--secondaryText);
}
</style>
