<template>
  <div
    :class="[onActive ? 'onActive' : '']"
    class="sideBarOptionsBox"
    :tabindex="props.tabindex"
    @click="triggerInput"
  >
    <div class="editor">
      <div class="left">
        <div class="textInput">
          <div class="title">{{ props.itemName }}</div>
          <textarea
            v-if="!isRichText"
            v-model="inputTxt"
            ref="textInput"
            class="input"
            :type="props.type"
            :placeholder="props.placeholder"
            :autocomplete="props.autocomplete"
            @input="$emit('update:modelValue', inputTxt)"
            @focus="onActive = true"
            @blur="onActive = false"
          />
        </div>
      </div>
    </div>
  </div>
  <SideBarItemDetail v-if="description" :description="props.description" />
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue"
import SideBarItemDetail from "./SideBarItemDetail.vue"

const props = defineProps({
  modelValue: String,
  itemName: String,
  description: String,
  text: String,
  isRichText: {
    type: Boolean,
    default: false,
  },
  tabindex: Number,
  autocomplete: String,
  type: {
    type: String,
    default: "text",
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "寫點什麼⋯⋯",
  },
})

let onActive = ref(false)
const showRichEditor = ref(false)

const textInput = ref(null)

if (props.autoFocus == true) {
  onActive.value = true
  setTimeout(() => textInput.value.focus())
}

const triggerInput = () => {
  if (!props.isRichText) {
    onActive.value = true
    setTimeout(() => textInput.value.focus())
  } else {
    showRichEditor.value = true
  }
}

const inputTxt = ref("")
watch(
  () => props.text,
  () => (inputTxt.value = props.text || ""),
  { immediate: true }
)

// const inputUpdate = () => {
//   onActive.value = false
//   inputTxt.value = textInput.value.innerText
//   emit("update:modelValue", inputTxt.value)
// }
const emit = defineEmits(["update:modelValue"])
watch(
  () => inputTxt.value,
  (val) => {
    if (props.isRichText) emit("update:modelValue", val)
  }
)
</script>

<style scoped>
.placeholder,
.editor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor.show {
  opacity: 1;
}

.editor.hide {
  opacity: 0;
  height: 0;
}

.input:empty::before {
  content: attr(data-placeholder);
  color: var(--placeholderText);
}

.input {
  height: 100px;
  width: 100%;
  overflow-y: hidden;
}

.onActive .input {
  height: 200px;
  overflow-y: scroll;
}

.left {
  flex: 1;
  overflow: hidden;
}
</style>
