<template>
  <Listbox v-model="selectedItem">
    <div class="relative">
      <ListboxButton class="button" :class="[isPicking ? 'onActive' : '']" @click="isPicking = !isPicking">
        <span>{{ selectedItem.label }}</span>
        <div class="icon rfs_symbol_chevron_down"></div>
      </ListboxButton>
      <ListboxOptions class="options">
        <ListboxOption v-slot="{ active, selected }" v-for="item in props.options" :key="item.name" :value="item" as="template" @click="isPicking = false">
          <li :class="[active ? 'active' : selected || isSameDefaultLabel(item.label) ? 'selected' : 'normal', 'item']">
            <span>{{ item.label }}</span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  defaultItem: {
    type: Object,
    default: () => {
      return {
        value: 0,
        label: '',
      }
    }
  }
})
const emits = defineEmits(['update:selectedItem'])

const isPicking = ref(false);
const selectedItem = ref(props.defaultItem)
const isSameDefaultLabel = (itemLabel) => itemLabel === props.defaultItem.label && selectedItem.value.label === itemLabel

watch(
  () => selectedItem.value,
  (item) => {
    emits('update:selectedItem', item)
  }
)
</script>

<style scoped>
.button {
  height: 48px;
  width: auto;
  padding: 0px 14px;
  border: 1px solid var(--borderNormal);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.options {
  box-shadow: var(--shadowMedium), inset 0 0 0 1px var(--borderNormal);
  width: auto;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 4px;
  padding: 8px;
  box-sizing: border-box;
  max-height: 190px;
  overflow: scroll;
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(48px);
  transform-origin: top left;
}

.item {
  position: relative;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.item.active {
  background: var(--filledHover);
}

.item.selected {
  background: var(--filledActive);
  color: var(--theme);
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
</style>
