<template>
  <div class="name">{{ name }}</div>
  <ul class="items">
    <MenuBarGroupItem v-for="item in items" :key="item" :icon="item.icon" :name="item.name" :target="item.target" />
  </ul>
</template>

<script>
import MenuBarGroupItem from "./MenuBarGroupItem.vue";

export default {
  props: {
    name: String,
    items: Object,
  },
  setup() {
    const setActive = () => {};

    return { setActive };
  },
  components: { MenuBarGroupItem },
};
</script>

<style scoped>
ul {
  padding: 0;
  margin: 0;
}

.name {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 12px;
  box-sizing: border-box;
  padding: 0px 12px;
  color: var(--secondaryText);
}
</style>
