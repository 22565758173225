<template>
  <div :class="[!showContent ? 'collapsed' : '', 'box']">
    <div class="header" @click="showContent = !showContent">
      <div class="left">
        <h1>{{ props.title }}</h1>
        <h1 v-show="props.description" class="description mono sub">{{ props.description }}</h1>
      </div>
      <div class="right">
        <button v-if="!showContent" class="icon rfs_symbol_chevron_down"></button>
        <button v-else class="icon rfs_symbol_chevron_up"></button>
      </div>
    </div>
    <ul v-show="showContent" class="content">
      <slot name="ContentCollapsibleListItems"></slot>
    </ul>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  title: String,
  description: String,
});

let showContent = ref(false);
</script>

<style scoped>
.box {
  background: var(--filledNormal);
  border: 1px solid var(--borderNormal);
  box-shadow: var(--shadowWeak);
  border-radius: 8px;
  width: 100%;
  display: block;
  padding: 0px 12px;
}

.box.collapsed:hover {
  box-shadow: var(--shadowMedium);
}

.header {
  padding: 24px 12px;
  box-sizing: border-box;
  box-shadow: 0 1px 0 0 var(--borderNormal);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .description {
  color: var(--secondaryText);
}

.header .right {
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
}
</style>
