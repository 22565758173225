<template>
  <ul v-if="props.label && props.sort">
    <li v-for="(item, index) in props.items" :key="item[sort]">
      <div class="item">
        {{ item[label] }}
      </div>
      <div class="options">
        <button @click="handleSort(index, 'forward')" :disabled="index == 0">
          <div class="rfs_symbol_chevron_up"></div></button
        ><button
          @click="handleSort(index, 'backward')"
          :disabled="index == props.items.length - 1"
        >
          <div class="rfs_symbol_chevron_down"></div>
        </button>
      </div>
    </li>
  </ul>
  <ul v-else>
    <li v-for="(item, index) in props.items" :key="index">
      <div class="item">
        {{ item }}
      </div>
      <div class="options">
        <button @click="handleSort(index, 'forward')" :disabled="index == 0">
          <div class="rfs_symbol_chevron_up"></div></button
        ><button
          @click="handleSort(index, 'backward')"
          :disabled="index == props.items.length - 1"
        >
          <div class="rfs_symbol_chevron_down"></div>
        </button>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { defineProps, watch, ref } from "vue"

const props = defineProps({
  items: Array,
  label: String,
  sort: String,
})

const result = ref([])

watch(
  () => props.items,
  () => {
    result.value = props.items
  }
)

const handleSort = (index, direction) => {
  if (direction == "forward") {
    if (props.sort) {
      result.value[index][props.sort] = index
      result.value[index - 1][props.sort] = index + 1
    }
    result.value.splice(index - 1, 0, result.value.splice(index, 1)[0])
  } else {
    if (props.sort) {
      result.value[index][props.sort] = index + 2
      result.value[index + 1][props.sort] = index + 1
    }
    result.value.splice(index + 1, 0, result.value.splice(index, 1)[0])
  }
}
</script>

<style scoped>
ul {
  background: #ffffff;
  box-shadow: inset 0 0 0 1px var(--borderNormal);
  border-radius: 8px;
  width: 100%;
  text-align: left;
  padding: 0px 16px;
  box-sizing: border-box;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

li {
  display: flex;
  height: 64px;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 var(--borderNormal);
  font-weight: 500;
}

li:last-child {
  box-shadow: none;
}

.item {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button > div {
  height: 20px;
  width: 20px;
  background-size: cover;
}

button {
  position: relative;
  z-index: 0;
}

button:disabled {
  cursor: not-allowed;
}

button:disabled > div {
  opacity: 0.3;
}

button:before {
  content: "";
  height: 24px;
  width: 24px;
  display: block;
  position: absolute;
  background: var(--filledHover);
  top: -2px;
  left: -2px;
  z-index: -1;
  border-radius: 8px;
  display: none;
}

button:hover::before {
  display: block;
}
</style>
