<template>
  <div class="richEditorContainer" @click="handleLeave">
    <div
      :class="[isNormal ? 'narrow' : 'extend', 'window']"
      @click.stop="$emit('update:isEdit', true)"
    >
      <div class="workingArea">
        <div class="editor">
          <div class="titles" v-if="isComplex">
            <div
              v-text="complexContent.title"
              class="main"
              placeholder="標題"
              ref="titleMainInput"
              contenteditable="plaintext-only"
              @keyup="titleMainUpdate"
              @keydown.enter.prevent
            ></div>
            <div
              v-text="complexContent.subTitle"
              class="sub"
              placeholder="標題"
              ref="titleSubInput"
              contenteditable="plaintext-only"
              @keyup="titleSubUpdate"
              @keydown.enter.prevent
            ></div>
          </div>
          <div class="content" ref="content">
            <editor-content :editor="editor" />
          </div>
        </div>
        <div v-if="editor" class="buttonContainer">
          <div class="buttons">
            <button
              v-if="isComplex"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            >
              <span class="icon rfs_abstract_largeTitle"></span>
            </button>
            <button
              v-if="isComplex"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            >
              <span class="icon rfs_abstract_smallTitle"></span>
            </button>
            <div v-if="isComplex" class="divider"></div>
            <button
              @click="editor.chain().focus().toggleBold().run()"
              :class="{ 'is-active': editor.isActive('bold') }"
            >
              <span class="icon rfs_abstract_bold"></span>
            </button>
            <button
              @click="editor.chain().focus().toggleBlockquote().run()"
              :class="{ 'is-active': editor.isActive('blockquote') }"
            >
              <span class="icon rfs_abstract_quote_eastern"></span>
            </button>
            <div v-if="isComplex" class="divider"></div>
            <button
              @click="setLink"
              :class="{ 'is-active': editor.isActive('link') }"
            >
              <span class="icon rfs_object_chain"></span>
            </button>
            <div v-if="isComplex" class="divider"></div>
            <label for="imageUpload">
              <span class="icon rfs_object_image"></span>
              <input
                id="imageUpload"
                type="file"
                accept="image/png,image/jpeg,image/jpg"
                @change="addImage($event)"
                style="display: none"
              />
            </label>
            <button v-if="isComplex" @click="addVideo">
              <span class="icon rfs_logo_YouTube"></span>
            </button>
            <div class="divider"></div>
            <div
              class="writingShield"
              :class="isRedFlag ? 'failed' : 'passed'"
              @mouseover="isFailedDetailShow = true"
              @mouseleave="isFailedDetailShow = false"
            >
              <div
                v-if="!isRedFlag"
                class="icon rfs_object_shield_checked"
              ></div>
              <div v-else class="failedPreview">
                <div class="icon rfs_object_shield_expression"></div>
                <div class="count">{{ redFlagWord.length }}</div>
              </div>
              <div v-if="isFailedDetailShow" class="failedDetail">
                <div>
                  <div class="title">寫作保護</div>
                  <div class="message">
                    <p v-if="isRedFlag">
                      發現了 {{ redFlagWord.length }} 個錯誤詞彙
                    </p>
                    <p v-else>一切正常。寫得不錯！</p>
                  </div>
                </div>

                <ul class="redFlagWords">
                  <li v-for="words in redFlagWord" :key="words">
                    {{ words }}
                  </li>
                </ul>
                <div class="divider horizontal" style="margin-top: 4px"></div>
                <div class="reminder">
                  <p v-if="isRedFlag">請您在發表文章前先修正這些錯誤。</p>
                  <p v-else>寫作保護會在您的措詞有誤時提醒您。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isNormal" class="narrowButton">
        <Button context="完成" type="primary" length="extend" @click="save" />
      </div>
      <div class="sideBar" v-if="isComplex">
        <!-- 這應該要寫成從狀態來判斷，如果是新文章，主要按鈕就是「發表」，次要按鈕就是「儲存為草稿」；
             如果是編輯舊文章，主要按鈕就是「更新」，次要按鈕就是「儲存為草稿」。 -->
        <SideBar
          :primaryButton="primaryButton"
          :primaryButtonDisabled="!titleMain"
          :secondaryButtonDisabled="!titleMain"
          :secondaryButton="secondaryButton"
          :isInteracting="true"
          title=""
          @primaryClick="submit('publish')"
          @secondaryClick="submit('hidden')"
        >
          <template v-slot:SideBarItems>
            <SideBarOptionGroup title="封面">
              <template #GroupOptions>
                <SideBarImage
                  v-model:image="editorHero"
                  itemName="圖片"
                  :image="props.complexContent.hero"
                />
              </template>
            </SideBarOptionGroup>
            <SideBarOptionGroup title="資料">
              <template #GroupOptions>
                <SideBarText
                  v-model="editorTag"
                  :text="tagsToString"
                  itemName="標籤"
                  placeholder="旅遊, 美食, 娛樂⋯⋯"
                  description="請以逗號分隔每個標籤。"
                />
              </template>
            </SideBarOptionGroup>
            <SideBarOptionGroup title="搜尋引擎最佳化">
              <template #GroupOptions>
                <SideBarTextArea
                  v-model="editorTxt"
                  :text="props.complexContent.description"
                  itemName="說明"
                  description="這些文字會出現在分享文章時的預覽，以及搜尋引擎的搜尋結果上。請簡單扼要的描述文章內容，來確保良好的閱讀體驗。"
                />
              </template>
            </SideBarOptionGroup>
            <div class="divider_temp"></div>
            <ButtonMini
              type="secondary"
              context="刪除文章"
              length="extend"
              @click="emit('delete')"
            />
          </template>
        </SideBar>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  onMounted,
  defineEmits,
  computed,
  watch,
  onBeforeUnmount,
  onBeforeMount,
} from "vue"
import { storeToRefs } from "pinia"
import axios from "axios"
import { useEditor, EditorContent } from "@tiptap/vue-3"
import { useDialogStore } from "@/store/dialog"
import StarterKit from "@tiptap/starter-kit"
import Image from "@tiptap/extension-image"
import Youtube from "@tiptap/extension-youtube"
import Blockquote from "@tiptap/extension-blockquote"
import Placeholder from "@tiptap/extension-placeholder"
import Link from "@tiptap/extension-link"
import SideBar from "./SideBar.vue"
import SideBarOptionGroup from "./SideBarOptionGroup.vue"
import SideBarImage from "./SideBarImage.vue"
import SideBarText from "./SideBarText.vue"
import SideBarTextArea from "./SideBarTextArea.vue"
import Button from "./Button.vue"
import ButtonMini from "./ButtonMini.vue"
import { writingShield } from "@/composables/writingShield"

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  type: {
    type: String,
    default: "", // normal, complex
  },
  isEdit: {
    type: Boolean,
    default: true,
  },

  // 複雜編輯器的內容
  complexContent: {
    type: Object,
    default: () => {
      return {
        title: "",
        subTitle: "",
        body: "",
        hero: "",
        thumbnail: "",
        tags: [],
        description: "",
        status: "",
      }
    },
  },

  articleId: {
    type: Number,
    default: undefined,
  },

  // 普通編輯器的內容
  content: {
    type: String,
    default: "",
  },
})

const titleMainInput = ref(null)
const titleSubInput = ref(null)

const titleMain = ref("")
const titleSub = ref("")

const content = ref(null)

// 類型辨識
const isComplex = ref(false)
const isNormal = ref(false)

if (props.type === "complex") {
  isComplex.value = true
} else if (props.type === "normal") {
  isNormal.value = true
}

// 自動聚焦
onMounted(() => {
  if (isComplex.value) {
    setTimeout(() => titleMainInput.value.focus())
  }
})

// 輸入監聽
// eslint-disable-next-line no-unused-vars
const emit = defineEmits([
  "update:titleMainContext",
  "update:isEdit",
  "update:content",
  "submit",
  "delete",
])

const titleMainUpdate = () => {
  titleMain.value = titleMainInput.value.innerText
}

const titleSubUpdate = () => {
  titleSub.value = titleSubInput.value.innerText
}

// 文章狀態辨識與按鈕切換
const isEditingExist = computed(() => !!props.articleId)
const isPublishArticle = computed(
  () => props.complexContent.status === "publish"
)
const primaryButton = computed(() =>
  isEditingExist.value && isPublishArticle.value ? "更新" : "發表"
)
const secondaryButton = computed(() =>
  isEditingExist.value && isPublishArticle.value ? "回復成草稿" : "儲存為草稿"
)

// 編輯器
const editorOutput = ref("")
const editor = ref(
  useEditor({
    content:
      props.type === "normal" ? props.content : props.complexContent.body,
    extensions: [
      StarterKit,
      Image,
      Placeholder.configure({
        placeholder: "說點什麼⋯⋯",
      }),
      Youtube.configure({
        controls: false,
      }),
      Blockquote,
      Link.configure({
        openOnClick: false,
      }),
    ],
    onUpdate: ({ editor }) => {
      editorOutput.value = editor.getHTML()
    },
  })
)

const isRedFlag = ref()
const redFlagWord = ref()
const isFailedDetailShow = ref(false)

const updateRedFlag = (text) => {
  redFlagWord.value = writingShield(text).redFlagWord
  isRedFlag.value = writingShield(text).isRedFlag
}

watch(
  [editorOutput, titleMain, titleSub],
  ([editorOutputValue, titleMainValue, titleSubValue]) => {
    const text = editorOutputValue || titleMainValue || titleSubValue
    updateRedFlag(text)
  }
)

const addImage = (event) => {
  if (event) {
    var form = new FormData()
    form.append("image", event.target.files[0])
    form.append("type", "file")

    const settings = {
      url: "https://api.imgur.com/3/image",
      method: "POST",
      headers: {
        Authorization: "Client-ID addc2e59cc8ae36",
      },
      data: form,
    }

    axios(settings)
      .then(function (response) {
        editor.value
          .chain()
          .focus()
          .setImage({
            src: response.link,
          })
          .run()
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

const addVideo = () => {
  const url = prompt("插入 YouTube 影片網址")
  const height = (content.value.offsetWidth - 96) / 1.78

  editor.value.commands.setYoutubeVideo({
    src: url,
    width: "100%",
    height: height,
  })
}

const setLink = () => {
  const previousUrl = editor.value.getAttributes("link").href

  // 移除
  if (previousUrl) {
    editor.value.chain().focus().unsetLink().run()

    return
  } else {
    let url = window.prompt("插入超連結", previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === "") {
      editor.value.chain().focus().extendMarkRange("link").unsetLink().run()

      return
    }

    if (
      url.substring(0, 7) !== "http://" ||
      url.substring(0, 8) !== "https://"
    ) {
      url = `https://` + url
    }

    editor.value
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run()
  }
}

// 儲存
const save = () => {
  emit("update:isEdit", false)
  emit("update:content", editor.value.getHTML())
}

// 新增、編輯豐富編輯器
const editorHero = ref("")
const editorTag = ref("")
const editorTxt = ref("")
const tagsToString = computed(() => {
  return props.complexContent.tags ? props.complexContent.tags.join(",") : ""
})

onBeforeMount(() => {
  if (Object.keys(props.complexContent).length !== 0) {
    editorHero.value = props.complexContent.hero
    titleMain.value = props.complexContent.title
    titleSub.value = props.complexContent.subTitle
    editorTag.value = props.complexContent.tags.join(",")
    editorTxt.value = props.complexContent.description
  }
})
const submit = (status) => {
  const emitData = {
    title: titleMain.value,
    subTitle: titleSub.value,
    body: editor.value.getHTML(),
    hero: editorHero.value,
    thumbnail:
      editorHero.value.split(".").slice(0, 3).toString().replaceAll(",", ".") +
      "h." +
      editorHero.value.split(".")[3],
    tags: editorTag.value.split(",").filter((item) => item),
    description: editorTxt.value || titleSub.value,
    status,
  }

  emit("submit", isEditingExist.value, emitData)
  emit("update:isEdit", false)
}

// 離開編輯器
const dialogStore = useDialogStore()
const { actions } = storeToRefs(dialogStore)
const handleLeave = () => {
  dialogStore.set({
    title: "離開文章",
    supportingText: "確定要離開嗎？系統不會自動儲存剛才撰寫的內容。",
    primaryButton: "確定",
    secondaryButton: "取消",
  })
}
watch(
  () => actions.value,
  (val) => {
    if (val === "離開文章") emit("update:isEdit", false)
  }
)
onBeforeUnmount(() => {
  dialogStore.action("")
})
</script>

<style scoped>
.richEditorContainer {
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000050;
  justify-content: center;
  align-items: center;
  padding: 32px 32px 0px 32px;
}

.workingArea {
  flex: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.titles {
  display: block;
  padding: 0px 48px;
  margin-bottom: 48px;
  width: 100%;
}

.titles > div {
  cursor: text;
}

.titles > div:focus {
  outline: none;
}

.titles > .main {
  font-size: 40px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 8px;
  line-height: 130%;
}

.titles > .main:empty::before {
  content: "標題";
  color: var(--placeholderText);
}

.titles > .sub {
  margin-top: 24px;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  line-height: 130%;
}

.titles > .sub:empty::before {
  content: "副標題";
  color: var(--placeholderText);
}
.content {
  height: 100%;
}

.editor .content > div {
  height: 100%;
}

.window {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0 13px 28px -8px rgba(0, 0, 0, 0.21),
    0 2px 4px 0 rgba(0, 0, 0, 0.03);
  border-radius: 16px 16px 0 0;
  display: flex;
}

.window.narrow {
  max-width: 800px;
  flex-direction: column;
}

.window.extend {
  max-width: 1200px;
}

.editor {
  padding: 48px 0px;
  overflow-y: scroll;
  height: 100%;
}

.divider_temp {
  width: 100%;
  height: 1px;
  background: var(--borderNormal);
  margin: 24px 0px;
}

.buttonContainer {
  height: 120px;
  width: 100%;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.buttons {
  height: 56px;
  display: inline-flex;
  background: #ffffff;
  box-shadow: 0 5px 6px -4px rgba(0, 0, 0, 0.23),
    0 8px 27px -5px rgba(0, 0, 0, 0.21),
    inset 0 0 0 1px rgba(219, 219, 219, 0.5);
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  pointer-events: auto;
}

.buttons button,
.buttons label {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.buttons button:hover,
.buttons label:hover {
  background: var(--filledHover);
}
.is-active {
  background: var(--filledActive);
}

.is-active:hover {
  background: var(--filledActive);
}

button.is-active .icon {
  opacity: 1;
  filter: invert(59%) sepia(59%) saturate(6024%) hue-rotate(2deg)
    brightness(100%) contrast(101%);
}

.buttons button:not(:last-child),
.buttons label:not(:last-child) {
  margin-right: 8px;
}

.buttons .icon {
  width: 24px;
  height: 24px;
  display: block;
  opacity: 0.8;
}

.divider {
  width: 2px;
  height: 24px;
  background: #eaeaea;
  margin-right: 14px;
  margin-left: 6px;
}

.divider.horizontal {
  width: 100%;
  height: 2px;
  background: #eaeaea;
  margin: 12px 0px;
}

.narrowButton {
  padding: 24px 48px;
}

/* writingShield */
.writingShield {
  display: flex;
  position: relative;
  cursor: help;
  margin-right: 4px;
}

.writingShield.passed:before {
  position: absolute;
  content: "";
  width: 32px;
  height: 32px;
  top: -4px;
  left: -4px;
  background: var(--filledHover);
  border-radius: 8px;
  opacity: 0;
}

.writingShield.passed:hover:before {
  opacity: 1;
}

.writingShield.failed {
  color: #ffffff;
}

.writingShield.failed:before {
  content: "";
  background: #ff502d;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  top: -4px;
  left: -4px;
  position: absolute;
  z-index: 0;
  border-radius: 100px;
}

.writingShield .failedPreview {
  display: flex;
  z-index: 1;
  padding-right: 8px;
  padding-left: 4px;
}

.writingShield .failedPreview .icon {
  margin-right: 4px;
  filter: invert(100%) sepia(41%) saturate(2%) hue-rotate(57deg)
    brightness(106%) contrast(100%);
}

.writingShield .failedPreview .count {
  font-weight: bold;
}

.writingShield .runningStatus {
  font-size: 13px;
  color: var(--primaryText);
  font-weight: bold;
}

.checkedStatus {
  margin-left: 6px;
}

.failedDetail {
  position: absolute;
  background: #ffffff;
  width: 300px;
  color: #000;
  transform-origin: bottom right;
  box-shadow: var(--shadowMedium), inset 0 0 0 1px #eaeaea;
  display: inline-block;
  right: -8px;
  bottom: 48px;
  height: auto;
  padding: 16px;
  border-radius: 8px;
}

.failedDetail .title {
  font-size: 13px;
  font-weight: 500;
  color: var(--secondaryText);
}

.failedDetail .message {
  font-size: 18px;
  font-weight: 500;
}

.failedDetail .reminder p {
  font-size: 13px;
  font-weight: 400;
  color: var(--secondaryText);
  line-height: 140%;
}

.redFlagWords {
  margin-top: 4px;
}
.redFlagWords li {
  display: inline-block;
  border-bottom: 1px solid #ff502d;
  color: #ff502d;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
</style>
