<template>
  <div :class="[read ? 'read' : 'unread', 'items']">
    <div class="iconContainer">
      <div class="icon rfs_abstract_bubble_ellipsis"></div>
    </div>
    <div class="text">
      <div class="subject">{{ props.subject }}</div>
      <div class="detail">{{ from }} • {{ props.time }}</div>
    </div>
    <div v-if="!read" class="indicator"></div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue"

const props = defineProps({
  read: Boolean,
  subject: String,
  type: String,
  time: String,
})

const from = ref("專欄")
</script>

<style scoped>
.items {
  align-items: stretch !important;
  padding: 12px 12px !important;
}
.items.unread {
  background: #fef7f2;
}

.items.unread:hover {
  background: #fef0e6;
}

.items.read {
  background: var(--filledNormal);
}

.items.read:hover {
  background: var(--filledHover);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.read .icon {
  opacity: 0.5;
}

.text {
  flex: 1;
  margin-right: 16px;
}

.subject {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
}

.read .subject {
  color: var(--secondaryText);
}

.detail {
  font-size: 13px;
  font-weight: 500;
  color: var(--secondaryText);
}

.indicator {
  width: 10px;
  display: flex;
  align-items: center;
}

.indicator:after {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background: var(--theme);
  border-radius: 100%;
}
</style>
