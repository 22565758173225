<template>
  <button :class="`${type} ${props.length} button`" :disabled="disabled">
    <span>{{ props.context }}</span>
  </button>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue"

const props = defineProps({
  context: String,
  length: String,
  type: String,
  disabled: Boolean,
})

const type = ref("")
const checkButtonType = () => {
  if (props.type !== "secondary" && props.type !== "primary") {
    console.error("型別錯誤")
    type.value = "secondary"
  } else {
    type.value = props.type
  }
}
onMounted(() => checkButtonType())
</script>

<style scoped>
.button {
  width: auto;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 16px;
}

.extend {
  width: 100%;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.primary {
  background: var(--theme);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(85, 36, 0, 0.28);
  border-radius: 8px;
  color: #ffffff;
}

.button.primary:hover {
  background: #e15f00;
}

.button.primary:active {
  background: #c85400;
}

.button.secondary {
  background: #fbfbfb;
  border: 1px solid #d5d5d5;
  box-shadow: var(--shadowWeak);
  border-radius: 8px;
}

.button.secondary:hover {
  background: #eeeeee;
}

.button.secondary:active {
  background: #e1e1e1;
}
</style>
