<template>
  <div class="dialogContainer">
    <div class="window">
      <div class="context">
        <h1 class="title">{{ props.title }}</h1>
        <p class="description">{{ props.supportingText }}</p>
      </div>
      <div class="actions">
        <ButtonMini
          v-if="!!primaryButton"
          type="secondary"
          :context="secondaryButton"
          @click="handleClick(secondaryButton)"
        />
        <ButtonMini
          v-if="!!secondaryButton"
          type="primary"
          :context="primaryButton"
          @click="handleClick(props.title)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue"
import { useDialogStore } from "@/store/dialog"
import ButtonMini from "./ButtonMini.vue"

const props = defineProps({
  title: String,
  supportingText: String,
  primaryButton: String,
  secondaryButton: String,
})

const dialogStore = useDialogStore()
const handleClick = (actionText) => {
  dialogStore.action(actionText)
  dialogStore.clear()
}
</script>

<style scoped>
.dialogContainer {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000050;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.window {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: var(--shadowStrong);
  max-width: 600px;
}

.context {
  margin-bottom: 24px;
}

.title {
  margin-bottom: 8px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions > button:not(:last-child) {
  margin-right: 8px;
}
</style>
