<template>
  <LoadingSpinner v-if="isLoading" />
  <div v-show="!isReordering && !isLoading" style="height: 100%">
    <SideBarGroup
      v-for="(item, index) in items"
      :key="`${index}-${item}`"
      :overviewContent="getOverviewContent(item, index)"
      :current="editingItem.key"
      :hasFormUnfilled="hasFormUnfilled"
      @expand="handleExpand(item, index)"
      @save="handleSave(index)"
      @delete="handleDelete(index)"
    >
      <template #items>
        <SideBarText
          v-model="skill"
          itemName="技能"
          :text="skill"
          placeholder="我的技能⋯⋯"
          :autoFocus="true"
        />
      </template>
    </SideBarGroup>
    <div v-if="items.length == 0" class="sideBarEmptyState">
      <!-- <div class="illustration">
      <img :src="props.emptyStates.illustration" width="100" height="100" />
    </div> -->
      <div class="messages">
        <h1>空無一物</h1>
        <h1 class="sub">請點選「新增」來開始撰寫。</h1>
      </div>
    </div>
  </div>
  <SideBarReordering v-show="isReordering" :items="items" />
</template>

<script setup>
import { ref, defineProps, watch, inject, onBeforeMount, computed } from "vue"
import { useLoading } from "@/composables/useLoading"
import { storeToRefs } from "pinia"
import { useStashStore } from "@/store/stash"
import { useMessageStore } from "@/store/message"
import SideBarGroup from "../../SideBarGroup.vue"
import SideBarText from "../../SideBarText.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import SideBarReordering from "../../SideBarReordering.vue"

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  isReordering: {
    type: Boolean,
    default: false,
  },
})

const items = ref([])

const stashStore = useStashStore()
const messageStore = useMessageStore()
const { data } = storeToRefs(stashStore)
const { actions } = storeToRefs(messageStore)

watch(
  () => props.count,
  (val) => {
    if (val > 0) {
      items.value.push("")
      handleExpand("", items.value.length - 1)
    }
  }
)
const editingItem = ref({
  key: -1,
  text: "",
})
const handleExpand = (item, key) => {
  editingItem.value = { key, text: item }
  hasFormUnfilled.value = false
}
const getOverviewContent = (item, key) => {
  return {
    key,
    title: "技能",
    description: item,
  }
}

const skill = ref("")
const hasFormUnfilled = ref(false)

// 儲存
const handleSave = (index) => {
  return hasChanged.value ? checkSkills(index) : handleExpand("", -1)
}
const checkSkills = (index) => {
  hasFormUnfilled.value = skill.value === ""

  if (!hasFormUnfilled.value) {
    setSkillsData("edit", index)
    editSkills()
  }
}
const hasChanged = computed(() => skill.value !== editingItem.value.text)

// 更新順序
watch(
  () => props.isReordering,
  () => {
    if (!props.isReordering) {
      emitAction.value = "edit"
      editSkills()
    }
  }
)

// 刪除
const stashItems = ref([])
const handleDelete = (index) => {
  if (items.value[index] === "") {
    items.value.splice(index, 1)
  } else {
    setSkillsData("delete", index)
    stashStore.stash(stashItems.value)
    editSkills()
  }
}

const emitAction = ref("")
const setSkillsData = (action, index) => {
  switch (action) {
    case "edit":
      items.value[index] = skill.value
      emitAction.value = "edit"
      break
    case "delete":
      stashItems.value = JSON.parse(JSON.stringify(items.value)).filter(
        (item) => item !== ""
      )
      items.value.splice(index, 1)
      emitAction.value = "delete"
      break
  }

  items.value = items.value.filter((item) => item !== "")
}
watch(
  () => actions.value,
  (val) => {
    if (val === "復原") {
      items.value = data.value
      emitAction.value = ""
      editSkills()
      messageStore.action("")
      stashStore.stash([])
    }
  }
)

// 取得個人檔案的技能資料
const userId = localStorage.getItem("userId")
const $http = inject("http")
const { load, unload, isLoading } = useLoading()
const getSkills = async () => {
  load()
  await $http
    .get("/about")
    .then((res) => {
      items.value = res.usersInfo.find((user) => user.userId === userId).skills
    })
    .catch((err) => {
      console.error(err)
    })
  unload()
}
onBeforeMount(() => getSkills())

// 編輯、新增、刪除個人檔案的技能資料
const editSkills = async () => {
  load()
  await $http
    .put("/about", {
      params: {
        userId,
        type: "skills",
      },
      data: items.value,
    })
    .then(() => {
      handleExpand("", -1)
      handleMessage()
    })
    .finally(() => {
      getSkills()
    })
  unload()
}
const handleMessage = () => {
  switch (emitAction.value) {
    case "delete":
      messageStore.set({ msg: "已刪除", ctaText: "復原" })
      break
    case "edit":
      messageStore.set({ msg: "已儲存", ctaText: "確定" })
      break
    default:
      messageStore.clear()
      break
  }
}

watch(
  () => editingItem.value.key,
  () => {
    skill.value = editingItem.value.text || ""
  },
  { immediate: true }
)
</script>
<style scoped></style>
