<template>
  <div class="spinnerBox">
    <div class="spinner"></div>
  </div>
</template>

<script></script>

<style scoped>
.spinnerBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 28px;
  height: 28px;
  display: block;
  background: url("~@/assets/img_spinner.png");
  background-size: cover;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
