<template>
  <LoadingSpinner v-if="isLoading"/>
  <template v-else>
    <SideBarOptionGroup title="資料">
      <template #GroupOptions>
        <SideBarText v-model="userName" itemName="姓名" :text="userName" description="姓名會顯示在專欄上。" />
        <SideBarText v-model="instagramLink" itemName="Instagram" :text="instagramLink" description="Instagram 會顯示在文章作者旁。" />
      </template>
    </SideBarOptionGroup>
    <SideBarOptionGroup title="自我介紹">
      <template #GroupOptions>
        <SideBarText v-model="introduction" itemName="內容" :text="introduction" isRichText/>
      </template>
    </SideBarOptionGroup>
  </template>
</template>

<script setup>
import { ref, inject, onBeforeMount, defineProps, defineEmits, watchEffect } from 'vue'
import { useLoading } from "@/composables/useLoading";
import SideBarOptionGroup from "../../SideBarOptionGroup.vue";
import SideBarText from "../../SideBarText.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const emit = defineEmits(['update:userInfo'])

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  userInfo: {
    type: Object,
    default: () => {}
  }
});

const userName = ref('')
const instagramLink = ref('')
const introduction = ref('')

// 取得營養師資料
const userId = localStorage.getItem('userId')
const $http = inject("http");
const { load, unload, isLoading } = useLoading()

const getInformation = async () => {
  load()
  await $http.get("/about")
    .then((res) => {
      const userInfo = res.usersInfo.find((user) => user.userId === userId)
      
      userName.value = userInfo.userName || ''
      instagramLink.value = userInfo.link || ''
      introduction.value = userInfo.introduction || ''
    })
    .catch((err) => {
      console.error(err)
    })
  unload()
}
onBeforeMount(() => getInformation())

// 像父層傳送修改後的資料
watchEffect(
  () => {
    const editedItem = {
      userName: userName.value,
      link: instagramLink.value,
      introduction: introduction.value
    }
    emit('update:userInfo', editedItem)
  },
)
</script>
<style scoped></style>
