import { defineStore } from 'pinia'

export const useStashStore = defineStore({
  id: 'stash',
  state: () => ({
    data: [],
  }),

  actions: {
    stash(data) {
      this.data = data
    },
    clear() {
      this.data = []
    }
  },
})