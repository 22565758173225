import axios from 'axios'
import getErrorMessage from './error-code'
import { useLogout } from "@/composables/useLogout"

export default function () {
  const { logout } = useLogout()

  // response攔截器
  axios.interceptors.response.use(
    response => {
      const { data, success } = response.data
      return success ? data : {}
    },
    error => {
      if (error && error.response) {
        handleError(error.response.status)

        return Promise.reject({
          code: error.response.status,
          message: getErrorMessage(error.response.status),
        })
      }
      
      if (error && error.message === 'Network Error') return logout()

      return Promise.reject(error)
    },
  )

  // 處理錯誤的方法
  const handleError = (statusCode) => {
    switch (statusCode) {
      case 401:
        logout()
        break
      default: break
    }
  }
}
