<template>
  <div :class="[isFocus ? 'onActive' : '']" class="searchBar focusing">
    <div class="icon rfs_object_magnifyGlass"></div>
    <input @focus="isFocus = true" @blur="isFocus = false" v-model="inputTxt" @keyup="handleKeyUp" class="input" type="text" placeholder="搜尋⋯⋯" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";

const emit = defineEmits(['update:modelValue'])
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
const isFocus = ref(false);

const inputTxt = ref("");
const handleKeyUp = () => {
  setTimeout(() => {
    emit("update:modelValue", inputTxt.value);
  }, 500);
};
</script>

<style scoped>
.searchBar {
  display: flex;
  height: 48px;
  width: 100%;
  max-width: 224px;
  padding: 0px 14px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid var(--borderNormal);
  border-radius: 8px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.input {
  flex: 1;
  width: 100%;
}
</style>
