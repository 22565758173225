<template>
  <div
    @mouseover="showOptions = true"
    @mouseleave="showOptions = false"
    style="overflow: hidden"
  >
    <div class="information">
      <div class="left">
        <img :src="props.comment.avatar" class="avatar" />
      </div>
      <div class="right">
        <div>
          <h2>{{ props.comment.userName }}</h2>
          <div class="detail">{{ createdTime }}</div>
        </div>
        <div v-show="showOptions">
          <button
            class="icon rfs_object_trashCan"
            @click="$emit('remove', 'comment', props.comment.id)"
          ></button>
        </div>
      </div>
    </div>
    <div class="context">{{ props.comment.content }}</div>
    <div class="reply">
      <div class="replyedContext" v-if="isReplyWrote && !isReplyOnWriting">
        <h2 class="sub" style="margin-bottom: 4px">回覆</h2>
        <div>{{ props.comment.reply.content }}</div>
      </div>
      <div class="replyButton" v-if="!isReplyOnWriting">
        <ButtonMini
          v-if="!isReplyWrote"
          context="回覆"
          type="secondary"
          length="extend"
          @click="isReplyOnWriting = true"
        />
        <ButtonMini
          v-else
          context="編輯"
          type="secondary"
          length="extend"
          @click="isReplyOnWriting = true"
        />
      </div>
      <div class="replyBox" v-if="isReplyOnWriting">
        <textarea
          v-model="replyTxt"
          ref="replyArea"
          class="replyTextarea"
          placeholder="回覆留言⋯⋯"
          @blur="handleBlur"
        ></textarea>
        <div v-show="hasMessage" class="messageBox">
          <div v-show="hasError" class="message">
            發生某些錯誤，請稍候再試一次。
          </div>
        </div>
        <ButtonMini
          context="發表"
          type="primary"
          length="extend"
          @mouseover="handleMouse('over')"
          @mouseleave="handleMouse('leave')"
          @click="publish"
        />
      </div>
      <!-- <LoadingSpinner class="loading" v-if="isLoading" /> -->
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  onBeforeMount,
  computed,
  inject,
  watch,
} from "vue"
import { useCalendar } from "@/composables/useCalendar"
import ButtonMini from "./ButtonMini.vue"

const { calendarFormat } = useCalendar()
const $dayjs = inject("dayjs") // 引用 dayjs 這個變數來使用

const emits = defineEmits(["remove", "update", "reply"])
const props = defineProps({
  comment: {
    type: Object,
    default: () => {
      return {
        id: "",
        avatar: null,
        userName: "",
        createdTime: "",
        content: "",
        reply: "",
      }
    },
  },
  errorCommentId: {
    type: String,
    default: "",
  },
})

const showOptions = ref(false)

const isReplyWrote = ref(false)
const replyTxt = ref("")
onBeforeMount(() => {
  replyTxt.value = props.comment.reply ? props.comment.reply.content : ""
  isReplyWrote.value = !!props.comment.reply && replyTxt.value !== ""
})

const createdTime = computed(() => {
  const date = $dayjs(props.comment.createdTime)
  const isCurrentYear = $dayjs().isSame(date, "year")

  return $dayjs(date).calendar(null, calendarFormat(isCurrentYear))
})

const replyArea = ref(null)
const isReadyPublish = ref(false)
const isReplyOnWriting = ref(false)
const handleBlur = () => {
  if (replyTxt.value === "" && !isReadyPublish.value)
    isReplyOnWriting.value = false
}
const handleMouse = (action) => {
  switch (action) {
    case "over":
      isReadyPublish.value = true
      break
    case "leave":
      isReadyPublish.value = false
      break
  }
}
watch(
  () => isReplyOnWriting.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        replyArea.value.focus()
      })
    }
  }
)

// 發表留言
const publish = () => {
  if (isReplyWrote.value) {
    replyTxt.value
      ? emits("update", props.comment.id, {
          commentId: props.comment.reply.id,
          content: replyTxt.value,
        })
      : emits("remove", "reply", props.comment.reply.id)
  } else {
    replyTxt.value
      ? emits("reply", props.comment.id, {
          reply: props.comment.id,
          content: replyTxt.value,
        })
      : (isReplyOnWriting.value = false)
  }
}

// 錯誤訊息
const hasError = computed(
  () => props.comment.id !== "" && props.errorCommentId === props.comment.id
)
const hasMessage = computed(() => hasError.value)
watch(
  () => hasError.value,
  (val) => {
    if (val) {
      isReplyOnWriting.value = true
      setTimeout(() => replyArea.value.focus())
    }
  },
  { immediate: true }
)
</script>

<style scoped>
.information {
  display: flex;
  margin-bottom: 8px;
}

.information .left {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 8px;
}

.avatar {
  border-radius: 100%;
  overflow: hidden;
}

.information .right {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon:hover {
  opacity: 0.5;
}

.context {
  font-size: 15px;
  opacity: 0.8;
  margin-bottom: 16px;
  padding-left: 48px;
  overflow-wrap: break-word;
}

.reply {
  padding-left: 48px;
}

.replyedContext {
  background: #f3f3f3;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 15px;
}

.replyTextarea {
  resize: vertical;
  min-height: 100px;
  background: var(--filledHover);
  border-radius: 8px;
  width: 100%;
  font-size: 15px;
  padding: 12px;
  box-sizing: border-box;
  max-height: 400px;
}

.reply .content {
  font-size: 14px;
}

.messageBox {
  margin-bottom: 8px;
}
</style>
