<template>
  <div v-show="props.isInteracting" class="sideBarContainer">
    <div class="header" v-show="props.title">
      <div class="left">
        <h2 class="sub">正在編輯</h2>
        <h1>{{ props.title }}</h1>
      </div>
      <div class="right" v-if="props.reorderable">
        <button v-if="!props.isReordering" @click="toggleReordering">
          <div class="icon rfs_arrow_upAndDown"></div>
        </button>
        <ButtonMini
          v-else
          type="secondary"
          context="儲存"
          @click="toggleReordering"
        />
      </div>
    </div>
    <div class="content">
      <slot name="SideBarItems"></slot>
    </div>
    <div v-show="!props.isReordering" class="buttons">
      <Button
        v-show="secondaryButton"
        :context="secondaryButton"
        :disabled="secondaryButtonDisabled"
        type="secondary"
        length="extend"
        @click="$emit('secondaryClick')"
      />
      <Button
        v-show="primaryButton"
        :context="primaryButton"
        :disabled="primaryButtonDisabled"
        type="primary"
        length="extend"
        @click="$emit('primaryClick')"
      />
    </div>
  </div>
  <div
    v-if="!!props.emptyStates && !props.isInteracting"
    class="sideBarEmptyState"
  >
    <div class="illustration" v-if="props.emptyStates.illustration">
      <img :src="props.emptyStates.illustration" width="100" height="100" />
    </div>
    <div class="messages">
      <h1>{{ props.emptyStates.header }}</h1>
      <h1 class="sub">
        {{ props.emptyStates.message
        }}<a
          v-if="props.emptyStates.messageLink"
          :href="props.emptyStates.messageLink"
          >{{ props.emptyStates.messageLinkCTA }}</a
        >
      </h1>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
import Button from "./Button.vue"
import ButtonMini from "./ButtonMini.vue"

const isReordering = props.isReordering

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  state: {
    type: String,
    default: "",
  },
  primaryButton: {
    type: String,
    default: "",
  },
  primaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
  secondaryButton: {
    type: String,
    default: "",
  },
  secondaryButtonDisabled: {
    type: Boolean,
    default: false,
  },
  emptyStates: {
    type: Object,
    default: () => {},
  },
  isInteracting: {
    type: Boolean,
    default: false,
  },
  reorderable: {
    type: Boolean,
    default: true,
  },
  isReordering: {
    type: Boolean,
    default: false,
  },
})

const toggleReordering = () => {
  emit("reorderingClick")
  isReordering.value = !isReordering.value
}

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(["primaryClick", "secondaryClick", "reorderingClick"])
</script>

<style scoped>
.sideBarContainer {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  height: 72px;
  padding: 14px 16px;
  box-shadow: 0 1px 0 0 #e5e5e5;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 22px;
  height: 22px;
}

.sideBarContainer .content {
  padding: 24px 16px;
  /* display: grid;
  grid-row-gap: 8px;
  grid-template-rows: repeat(1, 1fr); */
  min-width: 378px !important;
  flex: 1;
  overflow: scroll;
}

.buttons {
  display: flex;
  padding: 16px;
  box-shadow: inset 0 1px 0 0 var(--borderNormal);
  width: 100%;
}

.buttons > button:not(:last-child) {
  margin-right: 8px;
}
</style>
