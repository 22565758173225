export const writingShield = (context) => {
  const redFlag = [
    "優化",
    "生成式",
    "視頻",
    "反饋",
    "截屏",
    "信息",
    "鎖屏",
    "攝像頭",
    "黑屏",
    "立馬",
    "自帶",
    "軟件",
    "硬件",
    "酸奶",
    "西紅柿",
    "早上好",
    "晚上好",
    "三觀",
    "表情包",
    "餐館",
    "低端",
    "高端",
    "屏蔽",
    "封禁",
    "科普",
    "打印",
    "概率",
    "無語",
    "拉黑",
    "我司",
    "牛逼",
    "數據庫",
  ]

  const isRedFlag = redFlag.some((word) => context.includes(word))
  const redFlagWord = redFlag.filter((word) => context.includes(word))

  return {
    isRedFlag,
    redFlagWord,
  }
}
