import { ref, computed } from "vue"

export const useLoading = () => {
  const loading = ref(0)
  const isLoading = computed(() => !!loading.value)

  const unload = () => {
    loading.value--
  }

  const load = () => {
    loading.value++
  }

  return {
    unload,
    load,
    isLoading,
  }
}