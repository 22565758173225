<template>
  <div class="card">
    <div class="back"></div>
    <div class="front">
      <div
        class="emoji"
        :class="theme"
        :style="`background: url(${illustrate(props.subject)})`"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue"

const props = defineProps({
  subject: String,
  theme: String,
})

const illustrate = (subject) => {
  if (props.theme == "pokemon") {
    switch (subject) {
      case "cat":
        return "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/906.png"
      case "dog":
        return "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/133.png"
      case "rabbit":
        return "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/54.png"
      case "mouse":
        return "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/25.png"
      case "chicken":
        return "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/722.png"
    }
  } else {
    switch (subject) {
      case "cat":
        return "https://openmoji.org/data/color/svg/1F408-200D-2B1B.svg"
      case "dog":
        return "https://openmoji.org/data/color/svg/1F415.svg"
      case "rabbit":
        return "https://openmoji.org/data/color/svg/1F407.svg"
      case "mouse":
        return "https://openmoji.org/data/color/svg/1F401.svg"
      case "chicken":
        return "https://openmoji.org/data/color/svg/1F413.svg"
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  transition: 0.2s;
  transform-style: preserve-3d;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.5s;
}

.card:hover {
  scale: 1.1;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16),
    0 37px 44px -13px rgba(0, 0, 0, 0.55);
}

.back {
  background: var(--theme);
}

.flipped,
.passed {
  transform: rotateY(180deg) !important;
}

.reset {
  transform: rotateY(180deg);
  animation: rotateAnimation 0.5s forwards;
  animation-delay: 1s;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.front,
.back {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.back:after {
  content: "";
  background: url("~@/assets/img_MatchingPairsCardBackLogo.jpg");
  height: 40%;
  aspect-ratio: 1 / 1;
  background-size: 100%;
}

.front {
  transform: rotateY(180deg);
  background: #ffffff;
  border: 2px solid #fb6a00;
}

.emoji {
  height: 60%;
  aspect-ratio: 1 / 1;
  background-size: cover !important;
}

.emoji.pokemon {
  height: 90%;
}
</style>
