<template>
  <LoadingSpinner v-if="isLoading" />
  <div v-show="!isReordering && !isLoading" style="height: 100%">
    <SideBarGroup
      v-for="item in items"
      :key="item.id"
      :overviewContent="getOverviewContent(item)"
      :current="editingItem.id"
      :hasFormUnfilled="hasFormUnfilled"
      @expand="handleExpand(item)"
      @save="handleSave(item.id)"
      @delete="handleDelete(item.id)"
    >
      <template #items>
        <SideBarText
          v-model="testimonialGiver"
          itemName="給予者"
          :text="testimonialGiver"
          :autoFocus="true"
        />
        <SideBarText
          v-model="testimonialCompany"
          itemName="單位"
          :text="testimonialCompany"
        />
        <SideBarImage v-model:image="testimonialImage" itemName="給予者照片" />
        <SideBarTextArea
          v-model="testimonialContext"
          itemName="評價"
          :text="testimonialContext"
        />
      </template>
    </SideBarGroup>
    <div v-if="items.length == 0" class="sideBarEmptyState">
      <!-- <div class="illustration">
      <img :src="props.emptyStates.illustration" width="100" height="100" />
    </div> -->
      <div class="messages">
        <h1>空無一物</h1>
        <h1 class="sub">請點選「新增」來開始撰寫。</h1>
      </div>
    </div>
  </div>
  <SideBarReordering
    v-show="isReordering"
    :items="items"
    label="name"
    sort="id"
  />
</template>

<script setup>
import { ref, defineProps, watch, inject, onBeforeMount, computed } from "vue"
import { useLoading } from "@/composables/useLoading"
import { storeToRefs } from "pinia"
import { useStashStore } from "@/store/stash"
import { useMessageStore } from "@/store/message"
import SideBarGroup from "../../SideBarGroup.vue"
import SideBarImage from "../../SideBarImage.vue"
import SideBarText from "../../SideBarText.vue"
import SideBarTextArea from "../../SideBarTextArea.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import SideBarReordering from "../../SideBarReordering.vue"

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  isReordering: {
    type: Boolean,
    default: false,
  },
})

const items = ref([])

const stashStore = useStashStore()
const messageStore = useMessageStore()
const { data } = storeToRefs(stashStore)
const { actions } = storeToRefs(messageStore)

watch(
  () => props.count,
  (val) => {
    if (val > 0) {
      const newItem = {
        id: items.value.length + 1,
        name: "",
        title: "",
        text: "",
        image: "",
      }
      items.value.push(newItem)
      handleExpand(newItem)
    }
  }
)

const editingItem = ref({
  id: -1,
  name: "",
  title: "",
  text: "",
  image: "",
})
const handleExpand = (item) => {
  editingItem.value = item
  hasFormUnfilled.value = false
}
const getOverviewContent = (item) => {
  return {
    key: item.id,
    title: "評價",
    description: item.name,
  }
}

const testimonialGiver = ref("")
const testimonialCompany = ref("")
const testimonialImage = ref("")
const testimonialContext = ref("")
const hasFormUnfilled = ref(false)

// 儲存
const handleSave = (index) => {
  return hasChanged.value ? checkCourse(index) : handleExpand("", -1)
}
const checkCourse = (index) => {
  hasFormUnfilled.value = [
    testimonialGiver.value,
    testimonialCompany.value,
    testimonialImage.value,
    testimonialContext.value,
  ].includes("")

  if (!hasFormUnfilled.value) {
    setTestimonialData("edit", index)
    editTestimonial()
  }
}
const hasChanged = computed(() => {
  const editedItem = [
    testimonialGiver.value,
    testimonialCompany.value,
    testimonialImage.value,
    testimonialContext.value,
  ]
  return Object.keys(editingItem.value)
    .map((key) => {
      return key === "id" || editedItem.includes(editingItem.value[key])
    })
    .some((val) => val === true)
})

// 更新順序
watch(
  () => props.isReordering,
  () => {
    if (!props.isReordering) {
      emitAction.value = "edit"
      editTestimonial()
    }
  }
)

// 刪除
const stashItems = ref([])
const handleDelete = (index) => {
  if (items.value[index - 1].name === "") {
    items.value.splice(index - 1, 1)
  } else {
    setTestimonialData("delete", index)
    stashStore.stash(stashItems.value)
    editTestimonial()
  }
}

const emitAction = ref("")
const setTestimonialData = (action, index) => {
  switch (action) {
    case "edit":
      items.value[index - 1].name = testimonialGiver.value
      items.value[index - 1].title = testimonialCompany.value
      items.value[index - 1].image = testimonialImage.value
      items.value[index - 1].text = testimonialContext.value
      emitAction.value = "edit"
      break
    case "delete":
      stashItems.value = JSON.parse(JSON.stringify(items.value)).filter(
        (item) => item.name !== ""
      )
      items.value.splice(index - 1, 1)
      emitAction.value = "edit"
      break
  }

  items.value = items.value.filter((item) => item.name !== "")
}
watch(
  () => actions.value,
  (val) => {
    if (val === "復原") {
      items.value = data.value
      emitAction.value = ""
      editTestimonial()
      messageStore.action("")
      stashStore.stash([])
    }
  }
)

// 取得評價
const $http = inject("http")
const { load, unload, isLoading } = useLoading()
const getTestimonial = async () => {
  load()
  await $http
    .get("/about")
    .then((res) => {
      items.value = res.recommends
    })
    .catch((err) => {
      console.error(err)
    })
  unload()
}
onBeforeMount(() => getTestimonial())

// 編輯、新增、刪除評價資料
const editTestimonial = async () => {
  load()
  await $http
    .put("/about", {
      params: {
        type: "recommends",
      },
      data: items.value,
    })
    .then(() => {
      handleExpand("", -1)
      handleMessage()
    })
    .finally(() => {
      getTestimonial()
    })
  unload()
}
const handleMessage = () => {
  switch (emitAction.value) {
    case "delete":
      messageStore.set({ msg: "已刪除", ctaText: "復原" })
      break
    case "edit":
      messageStore.set({ msg: "已儲存", ctaText: "確定" })
      break
    default:
      messageStore.clear()
      break
  }
}

watch(
  () => editingItem.value.id,
  () => {
    testimonialGiver.value = editingItem.value.name || ""
    testimonialCompany.value = editingItem.value.title || ""
    testimonialImage.value = editingItem.value.image || ""
    testimonialContext.value = editingItem.value.text || ""
  },
  { immediate: true }
)
</script>
<style scoped></style>
