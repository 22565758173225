<template>
  <MatchingPairs />
  <Dialog
    v-if="isShowDialog"
    :title="dialogContent.title"
    :supportingText="dialogContent.supportingText"
    :primaryButton="dialogContent.primaryButton"
    :secondaryButton="dialogContent.secondaryButton"
  />
  <Snackbar
    v-if="isShowSnackBar"
    :message="snackBarContent.msg"
    :ctaText="snackBarContent.ctaText"
  />
  <aside>
    <MenuBar />
  </aside>
  <main>
    <header>
      <AppBar />
    </header>
    <article>
      <router-view />
    </article>
  </main>
</template>

<script setup>
import { computed, inject, onBeforeMount, watch } from "vue"
import { storeToRefs } from "pinia"
import { useMessageStore } from "@/store/message"
import { useDialogStore } from "@/store/dialog"
import { useInfoStore } from "@/store/info"
import MenuBar from "@/components/MenuBar.vue"
import AppBar from "@/components/AppBar.vue"
import Snackbar from "@/components/Snackbar.vue"
import Dialog from "@/components/Dialog.vue"
import { useRoute, useRouter } from "vue-router"
import MatchingPairs from "../components/MatchingPairs.vue"

const route = useRoute()
const router = useRouter()
const messageStore = useMessageStore()
const dialogStore = useDialogStore()
const infoStore = useInfoStore()
const { content: snackBarContent } = storeToRefs(messageStore)
const { content: dialogContent } = storeToRefs(dialogStore)
const isShowSnackBar = computed(() => messageStore.hasMessage)
const isShowDialog = computed(() => dialogStore.hasDialogMsg)

// 取得使用者的資訊
const $http = inject("http")
const userID = localStorage.getItem("userId")
const getUserInfo = async () => {
  await $http
    .get("/about")
    .then((res) => {
      const { userId, userEnName, userName, imageRound } = res.usersInfo.find(
        (user) => user.userId === userID
      )
      infoStore.setUserInfo({ userId, userEnName, userName, imageRound })
    })
    .catch((err) => {
      console.error(err)
    })
}
onBeforeMount(() => getUserInfo())

watch(
  () => route.name,
  (routeName) => {
    // 目前首頁無資料，暫時幫使用者導到內容頁
    if (routeName === "Page") router.push({ name: "Content" })
  },
  { immediate: true }
)
</script>

<style>
#app {
  display: flex;
  max-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  /* overflow: hidden; 
  .contentBox 會影響橫向捲動，暫時不要用。*/
}

header {
  height: 88px;
}

article {
  flex: 1;
  overflow: hidden;
}

button {
  background: none;
  border: 0;
  padding: 0;
}
</style>
