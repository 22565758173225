<template>
  <li @click="onClicked" :class="{ active: isActive }">
    <router-link :to="{ name: this.target }">
      <div :class="'icon ' + icon"></div>
      <div class="name">{{ name }}</div>
    </router-link>
  </li>
</template>

<script>
// import { ref } from 'vue'

export default {
  props: {
    icon: String,
    name: String,
    target: String,
    isActive: Boolean,
  },
  setup() {
    // const isClicked = ref(false)
    // const onClicked = () => {
    //   isClicked.value = true
    //   console.log(isClicked.value)
    // }
    // return { isClicked, onClicked }
  },
};
</script>

<style scoped>
li {
  list-style: none;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 4px;
}

li:hover {
  background: rgba(0, 0, 0, 0.05);
}

.router-link-active {
  background: #fee1cc;
  border-radius: 8px;
  color: #eb5200;
}

.router-link-active .icon {
  filter: invert(36%) sepia(72%) saturate(2563%) hue-rotate(13deg) brightness(96%) contrast(101%);
}

li a {
  height: 48px;
  box-sizing: border-box;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
}

li .icon {
  width: 22px;
  height: 22px;
  display: block;
  background-size: 100%;
  margin-right: 12px;
  border-radius: 100%;
}
</style>
