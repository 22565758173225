<template>
  <div>
    <div class="thumbnail"></div>
    <div class="information">
      <div class="dateAndVisibility">
        {{ createdTime }}・{{ postVisibility }}
      </div>
      <div class="title">{{ props.content.title }}</div>
      <div class="tags">
        <Chip v-for="tag in props.content.tags" :key="tag" :name="tag"></Chip>
      </div>
    </div>
    <div class="statistic">
      <div class="item">
        <h2 class="sub">點閱</h2>
        <h1 class="number">{{ props.content.views }} 次</h1>
      </div>
      <div class="item">
        <h2 class="sub">分享</h2>
        <h1 class="number">{{ props.content.shares }} 次</h1>
      </div>
    </div>
    <div class="comment">
      <h1 style="margin-bottom: 16px">留言</h1>
      <LoadingSpinner class="loading" v-if="loading" />
      <template v-else>
        <div class="comments" v-if="hasComments && !loading">
          <SideBarPublishingPanelComment
            v-for="comment in props.content.comments"
            :key="comment.id"
            :comment="comment"
            :errorCommentId="errorCommentId"
            @remove="removeComment"
            @update="updateComment"
            @reply="replyComment"
          >
          </SideBarPublishingPanelComment>
        </div>
        <div class="blank" v-else>沒有留言</div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, inject } from "vue"
import Chip from "./Chip.vue"
import SideBarPublishingPanelComment from "./SideBarPublishingPanelComment.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import { useCalendar } from "../composables/useCalendar"

const { calendarFormat } = useCalendar()
const $dayjs = inject("dayjs") // 引用 dayjs 這個變數來使用

const emit = defineEmits(["removeComment", "updateComment", "replyComment"])
const props = defineProps({
  content: {
    type: Object,
    default: () => {
      return {
        thumbnail: "",
        title: "無法取得文章標題",
        date: "無法取得張貼時間",
        tags: [],
        shares: "--",
        views: "--",
        visibility: "public",
        comments: [],
      }
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  errorCommentId: {
    type: String,
    default: "",
  },
})

const thumbnail = computed(() => {
  if (props.content.thumbnail == "h.undefined") {
    return require("@/assets/img_placeholder.jpg")
  } else {
    return props.content.thumbnail
  }
})

const hasComments = computed(() => {
  return props.content.comments ? props.content.comments.length > 0 : false
})

const postVisibility = computed(() =>
  props.content.status === "publish" ? "公開" : "草稿"
)

const createdTime = computed(() => {
  const date = $dayjs(props.content.createdTime)
  const isCurrentYear = $dayjs().isSame(date, "year")

  // return date;
  return $dayjs(date).calendar(null, calendarFormat(isCurrentYear))
})

const removeComment = (type, id) => emit("removeComment", type, id)
const updateComment = (id, content) => emit("updateComment", id, content)
const replyComment = (id, content) => emit("replyComment", id, content)
</script>

<style scoped>
.thumbnail {
  width: 346px;
  height: 178px;
  background: v-bind("'url(' + thumbnail + ')'");
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}
.dateAndVisibility {
  font-size: 14px;
  margin-bottom: 2px;
}
.information .title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
}

.information,
.statistic {
  padding-bottom: 24px;
  box-shadow: inset 0 -1px 0 0 var(--borderNormal);
}

.statistic,
.comment {
  display: flex;
  padding: 24px 0px;
}
.statistic .item {
  flex: 1;
  display: block;
  background: #f3f3f3;
  border-radius: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
}

.statistic .item:not(:last-child) {
  margin-right: 8px;
}

.comment {
  flex-direction: column;
}

.comments {
  display: grid;
  grid-row-gap: 24px;
  grid-template-rows: repeat(1, 1fr);
}

.comment .blank {
  height: 100px;
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.loading {
  height: 100px;
}
</style>
