export const useCalendar = () => {
  const calendarFormat = (isCurrentYear) => {
    return {
      sameDay: "今天 HH:mm",
      lastDay: "昨天 HH:mm",
      lastWeek: isCurrentYear ? "M 月 D 日 HH:mm" : "YYYY 年 M 月 D 日 HH:mm",
      sameElse: isCurrentYear ? "M 月 D 日 HH:mm" : "YYYY 年 M 月 D 日 HH:mm",
    };
  };

  return {
    calendarFormat,
  };
};
