import axios from 'axios';
import { API_URL } from '@/configs/env.config.json'

export default {
  /*
  * 取得 Token
  *
  */
  getToken () {
    return localStorage.getItem('token')
  },

  /**
   * get 請求
   * @param url 接口路徑
   * @param req 其他請求參數
   * @returns { import('axios').AxiosPromise }
   */
  get(url, req = undefined) {
    return axios({
      method: 'get',
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      url,
      ...req,
    })
  },

  /**
   * post 請求
   * @param url 接口路徑
   * @param req 其他請求參數
   * @returns { import('axios').AxiosPromise }
   */
  post(url, req = undefined) {
    return axios({
      method: 'post',
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      url,
      ...req,
    })
  },
  
  /**
   * put 請求
   * @param url 接口路徑
   * @param req 其他請求參數
   * @returns { import('axios').AxiosPromise }
   */
  put(url, req = undefined) {
    return axios({
      method: 'put',
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
      url,
      ...req,
    })
  },

  /**
   * delete 請求
   * @param url 接口路徑
   * @param req 其他請求參數
   * @returns { import('axios').AxiosPromise }
   */
  delete(url, req = undefined) {
    return axios({
      method: 'delete',
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      url,
      ...req,
    })
  },
}