<template>
  <div class="snackBarContainer">
    <div class="message">
      <p>{{ props.message }}</p>
    </div>
    <div class="callToAction">
      <button @click.stop="handleClick">{{ props.ctaText }}</button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue"
import { storeToRefs } from "pinia"
import { useMessageStore } from "@/store/message"

const props = defineProps({
  message: String,
  ctaText: String,
})

const messageStore = useMessageStore()
const { actions } = storeToRefs(messageStore)

if (messageStore.hasMessage && actions.value === "") {
  clearTimeout()
  setTimeout(() => messageStore.clear(), 5000)
}

const handleClick = () => {
  messageStore.action(props.ctaText)
  messageStore.clear()
}
</script>

<style scoped>
.snackBarContainer {
  position: fixed;
  bottom: 24px;
  left: 24px;
  display: flex;
  background: #212121;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22),
    0 13px 26px 1px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  min-height: 64px;
  width: 100%;
  max-width: 400px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  z-index: 30000;
}

.message {
  color: #ffffff;
  font-weight: 500;
}

.message p {
  line-height: 140%;
}

.callToAction {
  color: #ff8a35;
  width: auto;
  white-space: nowrap;
  margin-left: 16px;
}

.callToAction button {
  font-weight: 500;
}
</style>
