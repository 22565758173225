<template>
  <div class="trigger" @click="isTrigged = true"></div>
  <div class="playground" v-if="isTrigged">
    <div class="game">
      <div class="information">
        <div class="left">
          <h1>赤狐配對大贏家</h1>
          <div class="score">
            已嘗試 {{ step }} 次・已完成 {{ passed.length / 2 }} /
            {{ cardSubject.length }} 組
          </div>
        </div>
        <div class="right">
          <ButtonMini
            context="結束"
            type="secondary"
            @click="isTrigged = false"
          />
        </div>
      </div>
      <div class="cards">
        <MatchingPairsCard
          v-for="(card, index) of cards"
          :key="index"
          :subject="card"
          :theme="theme"
          @click="isGameOver ? null : flip([card, index])"
          :class="{
            flipped: firstAttendIndex === index || secondAttendIndex === index,
            reset: resetCards.includes(index),
            passed: passed.includes(index),
          }"
        />
      </div>
    </div>
    <div class="blanket"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue"
import MatchingPairsCard from "@/components/MatchingPairsCard.vue"
import ButtonMini from "@/components/ButtonMini.vue"

const isTrigged = ref(false)

const theme = computed(() => {
  const randomValue = Math.random()
  if (randomValue < 0.2) {
    return "pokemon"
  } else {
    return "animal"
  }
})

// 題目
const cardSubject = ref(["cat", "dog", "rabbit", "mouse", "chicken"])
const cards = ref([])

// 累積的嘗試次數
const step = ref(0)

onMounted(() => {
  const duplicatedArray = [...cardSubject.value, ...cardSubject.value]

  for (let i = duplicatedArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = duplicatedArray[i]
    duplicatedArray[i] = duplicatedArray[j]
    duplicatedArray[j] = temp
  }

  cards.value = duplicatedArray
})

// 目前需要猜中的卡
const goal = ref()

// 曾經猜中的卡片 index
const passed = ref([])

// 是否是新的一次猜測嘗試
const isFirstAttend = ref(true)

// 第一次與第二次猜測的卡片 index
const firstAttendIndex = ref()
const secondAttendIndex = ref()

// 重置
const resetCards = ref([])

// 翻牌
const flip = (card) => {
  resetCards.value = []
  if (isFirstAttend.value && !passed.value.includes(card[1])) {
    goal.value = card
    isFirstAttend.value = false
    firstAttendIndex.value = card[1]
  } else if (!passed.value.includes(card[1])) {
    secondAttendIndex.value = card[1]

    if (card[0] == goal.value[0] && card[1] !== goal.value[1]) {
      passed.value.push(firstAttendIndex.value, secondAttendIndex.value)
    } else {
      resetCards.value = [goal.value[1], card[1]]
      firstAttendIndex.value = null
      secondAttendIndex.value = null
    }

    goal.value = []
    isFirstAttend.value = true
    step.value++
  }
}

// 遊戲結束
const isGameOver = ref(false)

// // 重來
// const gameReset = () => {
//   step.value = 0
//   goal.value = ""
//   passed.value = ""
//   isFirstAttend.value = true
//   firstAttendIndex.value = ""
//   secondAttendIndex.value = ""
// }

watch(
  () => passed.value.length,
  () => {
    if (passed.value.length / 2 == cardSubject.value.length) {
      isGameOver.value = true
    }
  }
)
</script>

<style scoped>
.trigger {
  width: 32px;
  height: 32px;
  background: url("https://openmoji.org/data/color/svg/1F401.svg");
  position: fixed;
  bottom: 16px;
  left: 16px;
  opacity: 0;
  cursor: pointer;
}

.trigger:hover {
  opacity: 1;
}

.playground {
  z-index: 9999999;
  position: fixed;
  width: 100vw;
  height: 100svh;
  transition: 0.2s ease;
}

.blanket {
  background: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.game {
  margin: 0px 24px;
  background: #fff;
  height: calc(100% - 48px);
  top: 24px;
  width: 360px;
  display: flex;
  position: fixed;
  z-index: 1;
  border-radius: 24px;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  overflow: hidden;
}

.information {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.information h1 {
  font-size: 24px;
}

.information .score {
  margin-top: 4px;
  opacity: 0.6;
  font-size: 15px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 16px;
  height: 100%;
  perspective: 1000px;
}
</style>
