import { defineStore } from 'pinia'

export const useMessageStore = defineStore({
  id: 'message',
  state: () => ({
    content:{
      msg: '',
      ctaText: '',
    },
    actions: ''
  }),

  getters: {
    hasMessage: (state) => state.content.msg !== '',
  },

  actions: {
    set({ msg, ctaText }) {
      this.content = { msg, ctaText }
    },
    clear() {
      this.content = { msg: '', ctaText: '' }
    },
    action(text) {
      this.actions = text
    },
  },
})