<template>
  <nav>
    <div class="logo"></div>
    <div>
      <MenuBarGroup
        name="網站"
        :items="[
          { icon: 'rfs_abstract_collection', name: '內容', target: 'Content' },
          { icon: 'rfs_abstract_person', name: '個人檔案', target: 'Profile' },
          { icon: 'rfs_abstract_article', name: '專欄', target: 'Publishing' },
        ]"
      />
    </div>
  </nav>
</template>

<script>
import MenuBarGroup from "./MenuBarGroup.vue";

export default {
  components: { MenuBarGroup },
  setup() {
    // const MenuBarGroups = ref('{[groupName: "", groupItem: "總覽"], [groupName: "網站", groupItem: "['']"]}')
  },
};
</script>

<style scoped>
.logo {
  width: 153px;
  height: 30px;
  background: url("~@/assets/temp/logo.png");
  background-size: cover;
  margin: 0px 12px 32px 12px;
}

nav {
  background: #ffffff;
  width: 260px;
  box-sizing: border-box;
  padding: 32px 24px;
  height: 100vh;
  box-shadow: inset -1px 0 0 0 var(--borderNormal);
}
</style>
