<template>
  <div class="contentContainer">
    <div class="contentBox">
      <div class="content">
        <ContentCollapsibleList title="關於" description="/about">
          <template v-slot:ContentCollapsibleListItems>
            <ContentCollapsibleListItems
              title="沿革"
              @click="sideBarLoader('沿革', Course)"
              @keyup.enter="sideBarLoader('沿革', Course)"
              :active="isActive('沿革')"
            />
            <ContentCollapsibleListItems
              title="合作夥伴"
              @click="sideBarLoader('合作夥伴', Collaboration)"
              @keyup.enter="sideBarLoader('合作夥伴', Collaboration)"
              :active="isActive('合作夥伴')"
            />
            <ContentCollapsibleListItems
              title="評價"
              @click="sideBarLoader('評價', Testimonial)"
              @keyup.enter="sideBarLoader('評價', Testimonial)"
              :active="isActive('評價')"
            />
          </template>
        </ContentCollapsibleList>
        <ContentCollapsibleList title="專欄" description="/article">
          <template v-slot:ContentCollapsibleListItems>
            <ContentCollapsibleListItems
              title="分類"
              @click="sideBarLoader('分類', ArticleLabel)"
              @keyup.enter="sideBarLoader('分類', ArticleLabel)"
              :active="isActive('分類')"
            />
          </template>
        </ContentCollapsibleList>
      </div>
    </div>
    <div class="sideBar">
      <SideBar
        state="正在編輯"
        primaryButton="新增"
        :primaryButtonDisabled="isReordering"
        :title="sideBarTitle"
        :emptyStates="emptyStates"
        :isInteracting="!!sideBarTitle"
        @primaryClick="sideBarItemCount++"
        :isReordering="isReordering"
        @reorderingClick="isReordering = !isReordering"
      >
        <template v-slot:SideBarItems>
          <component
            v-if="!!sideBarCollection"
            :is="sideBarCollection"
            :count="sideBarItemCount"
            :isReordering="isReordering"
          />
        </template>
      </SideBar>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import ContentCollapsibleList from "@/components/ContentCollapsibleList.vue"
import ContentCollapsibleListItems from "@/components/ContentCollapsibleListItems.vue"
import SideBar from "@/components/SideBar.vue"

import Course from "@/components/SideBarCollections/Content/Course"
import Collaboration from "@/components/SideBarCollections/Content/Collaboration"
import Testimonial from "@/components/SideBarCollections/Content/Testimonial"
import ArticleLabel from "@/components/SideBarCollections/Content/ArticleLabel"

const emptyStates = {
  header: "編輯網頁內容",
  message: "選擇一個項目來開始編輯",
}

let sideBarTitle = ref("")
let sideBarCollection = ""

const sideBarLoader = (title, component) => {
  sideBarCollection = component
  sideBarTitle.value = title
  isReordering.value = false
}

const sideBarItemCount = ref(0)
const isReordering = ref(false)
const isActive = (type) => {
  return type === sideBarTitle.value
}
</script>

<style scoped>
.content > div:not(:last-child) {
  margin-bottom: 8px;
}
</style>
