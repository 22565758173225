import { createRouter, createWebHistory } from "vue-router"
import Page from "@/views/Page.vue"
// import Home from "@/views/Home.vue"
import Content from "@/views/Content.vue"
import Profile from "@/views/Profile.vue"
import Publishing from "@/views/Publishing.vue"
import Login from "@/views/Login.vue"
import SetNewPassword from "@/views/SetNewPassword.vue"

const routes = [
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
  {
    path: "/",
    name: "Page",
    component: Page,
    children: [
      {
        path: "/publishing/:id?",
        name: "Publishing",
        component: Publishing,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/content",
        name: "Content",
        component: Content,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/newpassword",
    name: "Set New Password",
    component: SetNewPassword,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
