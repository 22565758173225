<template>
  <button :class="`${type} ${props.length} button`">
    <span>{{ props.context }}</span>
  </button>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue";

const props = defineProps({
  context: String,
  length: String,
  type: String,
  isDisabled: Boolean,
});

const type = ref("");
const checkButtonType = () => {
  if (props.type !== "secondary" && props.type !== "primary") {
    console.error("型別錯誤");
    type.value = "secondary";
  } else {
    type.value = props.type;
  }
};
onMounted(() => checkButtonType());
</script>

<style scoped>
.button {
  width: auto;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 16px;
}

.extend {
  width: 100%;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.primary {
  background: #ffe9d9;
  border-radius: 8px;
  color: var(--theme);
}

.button.primary:hover {
  background: #f8decc;
}

.button.primary:active {
  background: #f4ceb2;
}

.button.secondary {
  background: #efefef;
  border-radius: 8px;
}

.button.secondary:hover {
  background: #e3e3e3;
}

.button.secondary:active {
  background: #d1d1d1;
}
</style>
