<template>
  <div class="box" tabindex="0">
    <div class="thumbnail"></div>
    <div class="information">
      <div class="top">
        <div class="titleAndVisibility">
          <h1 class="title">{{ props.content.title }}</h1>
          <div class="visibility">
            <span>{{ postVisibility }}</span>
          </div>
        </div>
        <h2 class="sub">{{ createdTime }}</h2>
      </div>
      <div class="bottom">
        <div class="tags">
          <Chip v-for="tag in props.content.tags" :key="tag" :name="tag"></Chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Chip from "./Chip.vue"
import { defineProps, computed, inject } from "vue"
import { useCalendar } from "../composables/useCalendar"
const { calendarFormat } = useCalendar()

// const dayjs = require("dayjs");
const $dayjs = inject("dayjs") // 引用 dayjs 這個變數來使用

const props = defineProps({
  content: Object,
})

const thumbnail = computed(() => {
  if (props.content.thumbnail == "h.undefined") {
    return require("@/assets/img_placeholder.jpg")
  } else {
    return props.content.thumbnail
  }
})

const createdTime = computed(() => {
  if (props.content.createdTime) {
    const date = $dayjs(props.content.createdTime)
    const isCurrentYear = $dayjs().isSame(date, "year")

    // return date;
    return $dayjs(date).calendar(null, calendarFormat(isCurrentYear))
  } else {
    return ""
  }
})

const postVisibility = computed(() =>
  props.content.status === "publish" ? "公開" : "草稿"
)
</script>

<style scoped>
.box {
  background: var(--filledNormal);
  border: 1px solid var(--borderNormal);
  box-shadow: var(--shadowWeak);
  border-radius: 8px;
  width: 100%;
  display: flex;
  padding: 20px;
  height: 160px;
}

.box:hover {
  box-shadow: var(--shadowMedium);
}

.thumbnail {
  width: 180px;
  height: 120px;
  background: v-bind("'url(' + thumbnail + ')'");
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.information {
  display: flex;
  flex-direction: column;
}
.information .top {
  flex: 1;
}
.titleAndVisibility {
  display: flex;
  align-items: center;
}
.title {
  margin-right: 8px;
}
.visibility {
  font-size: 11px;
  height: 20px;
  padding: 0px 4px;
  background: #eaeaea;
  border-radius: 4px;
}
</style>
